import { ApiCalls } from "../ApiCalls";
import axios from "axios";

export class Contact extends ApiCalls {
    async postContactForm(body: { title: string, description: string, email: string }): Promise<{ status: number, message: string }> {
            let data = await axios.post(`${this.url}${this.route}`, JSON.stringify(body), {
                headers: {
                    "Content-Type": "application/json"
                }
            }).then(response => {
                return { status: response.status, message: response.data.message }
            }).catch(err => {                
                return {status: err.response.status, message: err.response.statusText}
            });
            return data;
    }
}
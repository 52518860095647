import { ApiCalls } from "../ApiCalls";
import axios from "axios";

export class Images extends ApiCalls{
    public async getAllImages(){
        let response: {[key: string]: any } | Error;
        try {
            response = await axios.get(`${this.url}${this.route}`).catch(err => {
                return {
                    status: err.response.status,
                    message: err.response.message
                };
            });
        } catch (error) {
            response = {
                status: 510,
                message: "Unknown error occurred"
            };
        }
        return response;
    }
    public async getImageWithId(imageId: number){
        let response: {[key: string]: any } | Error;
        try {
            response = await axios.get(`${this.url}${this.route}/${imageId}`).catch(err => {
                return {
                    status: err.response.status,
                    message: err.response.message
                };
            });
        } catch (error) {
            response = {
                status: 510,
                message: "Unknown error occurred"
            };
        }
        return response;
    }
}
import InfoIcon from 'assets/images/svg/InfoIcon';
import Button from 'components/Button';
import { Input } from 'components/FormElements';
import TermsOfSale from 'components/TermsOfSale';
import { formatTimeWithDays } from 'constants/functions';
import { appActions } from 'contexts/AppContext/actions';
import { AppContext } from 'contexts/AppContext/reducer';
import { cartActions } from 'contexts/CartContext/actions';
import { CartContext, TTicketType } from 'contexts/CartContext/reducer';
import { ChannelsContext } from 'contexts/ChannelsContext/reducer';
import { StoreContext } from 'contexts/StoreContext/reducer';
import React, { useState, createRef, useContext } from 'react';
import './PricingForm.scss';

type PricingFormProps = {
    data: {
        pricing_id: number;
        price: number;
        valid_time: number;
        allowed_connections: number;
    }[],
    channelId: number;
    videoId: number;
    type: "videos" | "live";
    setModal: (hide?: boolean) => void;
}

const PricingForm: React.FC<PricingFormProps> = (props) => {
    const [terms, setTerms] = useState<JSX.Element | null>(null);
    const formRef = createRef<HTMLFormElement>();
    const appDispatch = useContext(AppContext).dispatch;
    const storeState = useContext(StoreContext).state;
    const channelsState = useContext(ChannelsContext).state;
    const { state, dispatch } = useContext(CartContext);

    const showSaleTerms = (hide?: boolean) => {
        if (!terms && !hide) {
            setTerms(<TermsOfSale close={() => showSaleTerms(true)} />)

        } else {
            setTerms(null);
        }
    }

    const getChannelPricings = () => {
        for (let channel of channelsState.channels) {
            if (channel.id === props.channelId && channel.pricing_scheme_id !== null) {
                if (storeState.prices[channel.pricing_scheme_id]) {
                    return <>
                        <tr className="table-section-header">
                            <td colSpan={4}>Kanavakohtaiset hinnat</td>
                        </tr>
                        {storeState.prices[channel.pricing_scheme_id].map((pricing, index) => {
                            return <tr key={index}>
                                <td>{pricing.price.toFixed(2)}€</td>
                                <td>{formatTimeWithDays(pricing.valid_time)}</td>
                                <td>{pricing.allowed_connections}</td>
                                <td><Input minValue={0} ariaLabel="Lippujen määrä" tabIndex={1} defaultValue="0" title="" type="number" name={`pricing_${pricing.pricing_id}_channels_${props.channelId}`} formRef={formRef} /></td>
                            </tr>
                        })}
                    </>
                }
            }
        }
        return null;
    }

    const buyTickets: React.FormEventHandler<HTMLFormElement> = (e) => {
        e.preventDefault();
        let event = e.nativeEvent as typeof e.nativeEvent & { submitter: HTMLButtonElement };
        let formData = new FormData(e.target as HTMLFormElement);
        let entries = Object.fromEntries(formData);
        let splitKey: string[] = [];
        let type: TTicketType;
        let id: string;
        let pricingId: string;
        let tempState = { ...state }.tickets;
        for (let key in entries) {
            if (key.includes("pricing_")) {
                if (Number(entries[key]) > 0) {
                    splitKey = key.split("_");
                    type = splitKey[2] as TTicketType;
                    id = splitKey[3];
                    pricingId = splitKey[1];

                    if (!tempState[type][id]) {
                        tempState[type][id] = {};
                    }
                    if (!tempState[type][id][pricingId]) {
                        tempState[type][id][pricingId] = Number(entries[key]);
                    } else {
                        tempState[type][id][pricingId] += Number(entries[key]);
                    }

                    dispatch({
                        type: cartActions.ADD_TO_CART,
                        value: { ...state.tickets, ...tempState }
                    });
                }
            }
        }

        if (splitKey.length === 0) {
            appDispatch({ type: appActions.SET_ALERT_MESSAGE, value: "Valitse vähintään yksi lipputyyppi" });
            return;
        } else if (event.submitter.classList.contains('buy') && props.setModal) {
            props.setModal();
        } else if (event.submitter.classList.contains('add')) {
            appDispatch({ type: appActions.SET_ALERT_MESSAGE, value: "Tuote lisätty ostoskoriin" });
            props.setModal(true);
        }
    }
    return (
        <>
            {terms}
            <form ref={formRef} action="" method="POST" onSubmit={buyTickets} className="PricingForm">
                <table className='PricingTable'>
                    <thead>
                        <tr>
                            <th>Hinta<InfoIcon scale={0.35} /><p>Lipun hinta, palvelumaksu on sisällytetty lipun hintaan.</p></th>
                            <th>Voimassa<InfoIcon scale={0.35} /><p>Lipun voimassaoloaika ensimmäisestä käyttökerrasta.</p></th>
                            <th>Katsojat<InfoIcon scale={0.35} /><p>Sallittujen samanaikaisten käyttäjien lukumäärä.</p></th>
                            <th>Määrä<InfoIcon scale={0.35} /><p>Ostettavien lippujen määrä</p></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="table-section-header">
                            <td colSpan={4}>Videokohtaiset hinnat</td>
                        </tr>
                        {props.data.map((pricing, index) => {
                            return <tr key={index}>
                                <td>{pricing.price.toFixed(2)}€</td>
                                <td>{formatTimeWithDays(pricing.valid_time)}</td>
                                <td>{pricing.allowed_connections}</td>
                                <td><Input minValue={0} ariaLabel="Lippujen määrä" tabIndex={1} defaultValue="0" title="" type="number" name={`pricing_${pricing.pricing_id}_${props.type}_${props.videoId}`} formRef={formRef} /></td>
                            </tr>
                        })}
                        {getChannelPricings()}
                    </tbody>
                </table>
                <Button tabIndex={1} className="buy" color="main-color" type="submit-regular">Osta liput</Button>
                <Button tabIndex={1} className="add" color="main-color" type="submit-regular">Lisää ostoskoriin</Button>
            </form>
        </>
    );
}

export default PricingForm;
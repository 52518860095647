import Link from 'components/Link';
import Page from 'components/Page';
import PageDescription from 'components/PageDescription';
import { routes } from 'constants/constants';
import React from 'react';
import './AccessibilityStatementPage.scss';

const AccessibilityStatementPage: React.FC = () => {
    return (
        <Page className="AccessibilityStatementPage">
            <PageDescription title="SAAVUTETTAVUUSSELOSTE">
                <p>Tämä saavutettavuusseloste koskee palvelua www.videokanava.fi ja on laadittu / päivitetty 04.11.2021. Palvelua koskee laki digitaalisten palvelujen tarjoamisesta, jossa edellytetään, että julkisten verkkopalvelujen on oltava saavutettavia.</p>
                <p>Olemme arvioineet palvelun saavutettavuuden itse</p>
            </PageDescription>
            <div className="container">
                <h2>Digipalvelun saavutettavuuden tila</h2>
                <section className="statement__section">
                    <p>Täyttää saavutettavuusvaatimukset osittain.</p>
                </section>

                <h2>Ei-saavutettava sisältö</h2>
                <section className="statement__section">
                    <h3>1. Ei kuulu lainsäädännön piiriin</h3>
                    <ul>
                        <li>käyttäjien tai muiden ulkopuolisten tahojen palvelussa julkaisemia sisältöjä, jotka eivät ole palveluntarjoajan itsensä tuottamia, rahoittamia tai valvomia</li>
                        <li>kulttuuriperintökokoelmien teosten ilmentymiä, joita ei voida muuttaa saavutettavuusvaatimukset täyttävään muotoon</li>
                    </ul>
                    <p>Kaikkiin sivustolla oleviin videomateriaaleihin ei ole lisätty tekstejä kohtuuttoman rasitteen vuoksi.</p>
                </section>

                <section className="statement__section">
                    <h3>2. Kohtuuton rasite</h3>
                    <p>Kaikkiin sivustolla oleviin videomateriaaleihin ei ole lisätty tekstejä kohtuuttoman rasitteen vuoksi. Tekstitysten lisääminen nostaisi palvelun hintaa kohtuuttomasti.</p>
                </section>

                <section className="statement__section">
                    <h3>3. Verkkosivusto ei ole vielä kaikilta osin vaatimusten mukainen</h3>
                    <ul className="flaws">
                        <li className="flaw">
                            <h4 className="flaw__title">Toimintavarma: Yhteensopivuus laitteissa ja selaimissa</h4>
                            <h5 className="flaw__subtitle">Saavuttamaton sisältö ja sen puutteet</h5>
                            <p>Sivustoa ei ole vielä testattu kaikilla laitteilla ja selaimilla.</p>
                            <p>Sivuston toimivuus on varmistettu Chromen, Firefoxin ja Edgen selaimilla.</p>
                            <p>Mobiililaitteiden ja televisioiden toimivuutta ei ole varmistettu. Voit testata videosoittimen toimivuutta sivuston monista ilmaisista videomateriaaleista.</p>
                            <h5>Saavutettavuusvaatimukset jotka eivät täyty</h5>
                            <ul className="flaw__wcag">
                                <li>4.1.3 Tilasta kertovat viestit</li>
                            </ul>
                        </li>
                    </ul>
                </section>

                <section className="statement__section">
                    <h2>Huomasitko saavutettavuuspuutteen digipalvelussamme? Kerro se meille ja teemme parhaamme puutteen korjaamiseksi</h2>
                    <h3>Verkkolomakkeella</h3>
                    <p><Link to={routes.contactPage.route}>www.videokanava.fi/contact</Link></p>
                    <h3>Sähköpostilla</h3>
                    <a href="mailto:tuki@videokanava.fi">tuki@videokanava.fi</a>
                </section>

                <section className="statement__section">
                    <h2>Valvontaviranomainen</h2>
                    <p>Jos huomaat sivustolla saavutettavuusongelmia, anna ensin palautetta meille eli sivuston ylläpitäjälle. Vastauksessa voi mennä 14 päivää. Jos et ole tyytyväinen saamaasi vastaukseen tai et saa vastausta lainkaan kahden viikon aikana, <Link to="https://www.saavutettavuusvaatimukset.fi/oikeutesi/" target="_blank">voit tehdä ilmoituksen Etelä-Suomen aluehallintovirastoon</Link>. Etelä-Suomen aluehallintoviraston sivulla kerrotaan tarkasti, miten ilmoituksen voi tehdä ja miten asia käsitellään.</p>
                    <h2>Valvontaviranomaisen yhteystiedot</h2>
                    <p>Etelä-Suomen aluehallintovirasto
                        Saavutettavuuden valvonnan yksikkö
                        www.saavutettavuusvaatimukset.fi
                        saavutettavuus(at)avi.fi
                        puhelinnumero vaihde 0295 016 000</p>
                </section>
            </div>

        </Page>
    );
}

export default AccessibilityStatementPage;
import Button from 'components/Button';
import React, {useEffect} from 'react';
import './Modal.scss';

type ModalProps = {
    close?: () => void
}

const Modal: React.FC<ModalProps> = (props) => {
    useEffect(() => {
        document.body.classList.add('stop-scroll');
        return () => {
            document.body.classList.remove('stop-scroll');
        }
    }, [])
    return(
        <div className='Modal'>
            <div className="modal-container">
            {props.children}
            {props.close ? <Button tabIndex={1} type='button-regular' color='main-color' onClick={props.close}>Sulje</Button> : null}         
            </div>
        </div>
    );
}

export default Modal;
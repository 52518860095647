const scrollToTop = () => {
    let scrollAnimation: NodeJS.Timeout;
    var previousPosition = document.body.scrollTop || document.documentElement.scrollTop;
    function listenToUserScroll() {
        if (previousPosition < (document.body.scrollTop || document.documentElement.scrollTop)) {
            clearTimeout(scrollAnimation);
            window.removeEventListener('scroll', listenToUserScroll);
        }
        previousPosition = document.body.scrollTop || document.documentElement.scrollTop;
    }

    window.addEventListener('scroll', listenToUserScroll);

    function doScroll() {
        function smoothScroll() {
            let position =
                document.body.scrollTop || document.documentElement.scrollTop;


            if (Math.floor(position) > 0) {
                let scrollAmount = -Math.max(1, Math.floor(position / 6));
                window.scrollBy(0, scrollAmount);
                scrollAnimation = setTimeout(() => doScroll(), 20);
            } else {
                clearTimeout(scrollAnimation);
                window.removeEventListener('scroll', listenToUserScroll);
            }
        }

        smoothScroll();
    }
    doScroll();
}
const clearFocus = (timeout: number) => {
    setTimeout(() => {
        if (document.activeElement && document.activeElement instanceof HTMLElement) {
            document.activeElement.blur();
        }
    }, timeout);
};

const addZeroesToStartOfString = (s: string, length: number) => {
    while (s.length < length) {
        s = '0' + s;
    }
    return s;
}

const formatTime = (time: number): string => {
    let h: string | number = Math.floor(time / 60 / 60);
    let m: string | number = Math.floor((time - h * 60) / 60);
    let s: string | number = Math.floor((time - (h * 60) - (m * 60)));
    h = h.toString();
    let times = [];
    if (h !== '0') times.push(addZeroesToStartOfString(h.toString(), 2));
    times.push(addZeroesToStartOfString(m.toString(), 2));
    times.push(addZeroesToStartOfString(s.toString(), 2));
    return `${times.join(':')}`;
}

const formatTimeWithDays = (time: number): string => {
    let d: string | number = Math.floor(time / 60 / 60 / 24);
    let h: string | number = Math.floor((time - d * 24 * 60 * 60) / 60 / 60);
    if (d === 0) return h + " tuntia";
    if (h === 0) return d + " päivää";
    return `${d} päivää ${h} tuntia`;
}

const parseJSON = (json: string | {}): {} => {
    if (typeof json === 'string') {
        json = JSON.parse(json);
    }
    return json;
}

const sleep = (ms: number) => new Promise(res => setTimeout(res, ms));

const filterArrayDuplicates = (arr: any[]): any[] => {
    return [...new Set(arr)];
}

const calculateOccurrences = (str1: string, str2: string) => str1.split(str2).length - 1;

const levenshteinDistance = (str1 = '', str2 = '') => {
    const track = Array(str2.length + 1).fill(null).map(() =>
        Array(str1.length + 1).fill(null));
    let i, j: number;
    for (i = 0; i <= str1.length; i++) {
        track[0][i] = i;
    }
    for (j = 1; j <= str2.length; j++) {
        track[j][0] = j;
    }
    for (j = 1; j <= str2.length; j++) {
        for (i = 1; i <= str1.length; i++) {
            const indicator = str1[i - 1] === str2[j - 1] ? 0 : 1; // 0, 
            track[j][i] = Math.min(
                track[j][i - 1] + 1, // deletion 
                track[j - 1][i] + 1, // insertion 
                track[j - 1][i - 1] + indicator, // substitution
            );
        }
    }
    let requiredInsertions = track[str2.length][str1.length];
    return requiredInsertions - (calculateOccurrences(str1, str2) * 1000);
};

const cosineDistance = (str1: string, str2: string) => {
    function chunkCountMap(str: string): { [key: string]: number } {
        const chunkSize = 2;
        const chunks: string[] = [];
        const numOfChunks = Math.ceil(str.length / chunkSize);

        for (let i = 0, o = 0; i < numOfChunks; ++i, o += chunkSize) {
            chunks[i] = str.substr(o, chunkSize);
        }

        let chunkCount: { [key: string]: number } = {};
        chunks.forEach((c) => {
            chunkCount[c] = (chunkCount[c] || 0) + 1;
        });
        return chunkCount;
    }
    function addChunksToDictionary(wordCountmap: { [key: string]: number }, dict: { [key: string]: boolean }) {
        for (let key in wordCountmap) {
            dict[key] = true;
        }
    }
    function chunkMapToVector(map: { [key: string]: number }, dict: { [key: string]: boolean }): number[] {
        let wordCountVector = [];
        for (let term in dict) {
            wordCountVector.push(map[term] || 0);
        }
        return wordCountVector;
    }
    function dotProduct(vec1: number[], vec2: number[]): number {
        let product = 0;
        for (let i = 0; i < vec1.length; i++) {
            product += vec1[i] * vec2[i];
        }
        return product;
    }

    function magnitude(vec: number[]): number {
        let sum = 0;
        for (let i = 0; i < vec.length; i++) {
            sum += vec[i] * vec[i];
        }
        return Math.sqrt(sum);
    }

    function cosineSimilarity(vec1: number[], vec2: number[]) {
        return dotProduct(vec1, vec2) / (magnitude(vec1) * magnitude(vec2));
    }

    const chunkCount1 = chunkCountMap(str1);
    const chunkCount2 = chunkCountMap(str2);
    let dict = {};
    addChunksToDictionary(chunkCount1, dict);
    addChunksToDictionary(chunkCount2, dict);

    const vector1 = chunkMapToVector(chunkCount1, dict);
    const vector2 = chunkMapToVector(chunkCount2, dict);

    return Number((1 - cosineSimilarity(vector1, vector2))) - (calculateOccurrences(str1, str2) * 1000);
}
const matchStringToArrValues = (arr: string[], value: string, returnWithValues?: boolean) => {
    let matchPercents: { value: string, match: number }[] = [];

    for (let val of arr) {
        matchPercents.push({ value: val, match: levenshteinDistance(val.toLowerCase(), value.toLowerCase()) });
    }
    matchPercents.sort((a, b) => (a.match > b.match) ? 1 : -1);

    let sortedValues: Array<string | { value: string, match: number }> = [];
    for (let val of matchPercents) {
        if (returnWithValues) {
            sortedValues.push(val);
        } else {
            sortedValues.push(val.value);
        }
    }
    return sortedValues;
};
const matchStringToObjArrValues = (arr: { [key: string]: any }[], value: string, returnWithValues?: boolean) => {
    let matchPercents: { value: { [key: string]: any }, match: number }[] = [];
    let match: number = 0;

    const recursion = (obj: any, currMatch: number = 0): number => {
        if (typeof (obj) === "string") {
            // lets not check values longer than this
            if (obj.length > 150) {
                return currMatch;
            }
            return currMatch + cosineDistance(obj.toLowerCase(), value.toLowerCase());
        } else if (typeof (obj) === "object") {
            for (let key in obj) {
                currMatch = recursion(obj[key], currMatch);
            }
        }
        return currMatch;
    }

    for (let val of arr) {
        for (let key in val) {
            if (typeof (val[key]) === 'string') {
                match += cosineDistance(val[key].toLowerCase(), value.toLowerCase());
            } else if (typeof (val[key]) === 'object') {
                match = recursion(val[key], match);
            }
        }
        matchPercents.push({ value: val, match });
        match = 0;
    }
    matchPercents.sort((a, b) => (a.match > b.match) ? 1 : -1);

    let sortedValues: Array<{ [key: string]: any }> = [];
    for (let val of matchPercents) {
        if (returnWithValues) {
            sortedValues.push(val);
        } else {
            sortedValues.push(val.value);
        }
    }

    return sortedValues;
}

const saveCookie = (cookieName: string, value: any, time: number) => {
    let cookie = [
        cookieName,
        '=',
        JSON.stringify(value),
        '; expires=',
        new Date(Date.now() + time).toUTCString(),
        'domain=.',
        window.location.host.toString(),
        '; path=/;'].join('');
    document.cookie = cookie;

}

const deleteCookie = (cookieName: string) => {
    document.cookie = cookieName + '=; Max-Age=-99999999;';
}

const setToSessionStorage = (key: string, value: string) => {
    window.sessionStorage.setItem(key, value);
}

const getCookieValue = (name: string): string => {
    let cookies = document.cookie.split('; ');
    let splitCookie: string[];
    if (cookies.length > 1) {
        for (let cookie of cookies) {
            splitCookie = cookie.split(name + '=');
            if (splitCookie.length > 1) {
                return splitCookie[1].split(' ')[0];
            }
        }
    } else {
        cookies = document.cookie.split(name + '=');
        if (cookies.length === 2) {
            return cookies[1].split(' ')[0];
        }
    }
    return "";
}

export {
    parseJSON,
    scrollToTop,
    formatTime,
    formatTimeWithDays,
    sleep,
    filterArrayDuplicates,
    matchStringToArrValues,
    matchStringToObjArrValues,
    levenshteinDistance,
    cosineDistance,
    getCookieValue,
    saveCookie,
    setToSessionStorage,
    deleteCookie,
    clearFocus
}
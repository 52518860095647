import Link from 'components/Link';
import { routes } from 'constants/constants';
import React from 'react';
import './Footer.scss';

const Footer: React.FC = () => {
    return(
        <footer className='Footer'>
            <div className="footer-column">
                <span className="title-4">Kulttuuriosuuskunta TERÄ / Videokanava</span>
                <ul aria-label="Yhteystiedot">
                    <li>Y-tunnus: 2501140-0</li>
                    <li><Link ariaLabel="Kulttuuriosuuskunta TERÄn kotisivu" to="http://www.kulttuuritera.fi/" color="link-light" target="_blank">http://www.kulttuuritera.fi/</Link></li>
                    <li><a aria-label="Sähköposti" href="mailto: tuki@videokanava.fi">tuki@videokanava.fi</a></li>
                    <li><a aria-label="Puhelinnumero" href="tel:+358-400-485-500">+358 400 485 500</a></li>
                    <li>Pakkalantie 123</li>
                    <li>36430 Sahalahti</li>
                </ul>
                <span className="footer-column-border"></span>
            </div>
            <div className="footer-column">
                <Link to={routes.privacyStatementPage.route}>{routes.privacyStatementPage.name}</Link>
                <Link to={routes.accessibilityStatementPage.route}>{routes.accessibilityStatementPage.name}</Link>
                <Link to={routes.storePage.route}>Myynnin ehdot</Link>
            </div>
        </footer>
    );
}

export default Footer;
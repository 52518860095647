import React, { createContext } from 'react';
import { videosActions } from './actions';

type TAction = {
    type: string;
    value?: {
        channelId: number;
        videos?: TVideoData[];
        liveEvents?: TLiveData[];
    };
}

type TData = {
    id: number;
    image_id: number;
    channel_id: number;
    embed_code: string | undefined;
    channel_title: string;
    title: string;
    description: {
        type: "subtitle" | "paragraph",
        text: string;
    }[],
    tags: string[],
    start_date: null | number,
    end_date: null | number,
    ticket_limit: number | null;
    pricing_scheme_id: number;
    active: number;
}

interface TVideoData extends TData {
    length: number;

}

interface TLiveData extends TData {
    chat: number;
}

type TVideosState = {
    [channelId: number]: {
        searchedAllVideos: boolean;
        searchedAllLiveEvents: boolean;
        videos: TVideoData[] | undefined;
        liveEvents: TLiveData[] | undefined;
    }
}

const initialVideosState: TVideosState = {
}

function videosReducer(state: TVideosState, action: TAction): TVideosState {

    if (action.value) {
        if (!state[action.value.channelId]) {
            state[action.value.channelId] = { searchedAllVideos: false, searchedAllLiveEvents: false, videos: [], liveEvents: [] };
        }
        if (!state[action.value.channelId].videos) {
            state[action.value.channelId].videos = [];
        }
        if (!state[action.value.channelId].liveEvents) {
            state[action.value.channelId].liveEvents = [];
        }
    }

    switch (action.type) {
        case videosActions.SET_ALL_VIDEOS:
            if (action.value) {
                state[action.value.channelId].searchedAllVideos = true;
                state[action.value.channelId].videos = [...state[action.value.channelId].videos as TVideoData[], ...action.value.videos as TVideoData[]]
                    .filter((video, index, self) =>
                    index === self.findIndex((v) => (
                        v.id === video.id
                    ))
                );
            }         
            break;
        case videosActions.APPEND_VIDEOS:
            if (action.value) state[action.value.channelId].videos = [...state[action.value.channelId].videos as TVideoData[], ...action.value.videos as TVideoData[]];
            break;
        case videosActions.SET_ALL_LIVE_EVENTS:
            if (action.value) {
                state[action.value.channelId].searchedAllLiveEvents = true;
                state[action.value.channelId].liveEvents = [...state[action.value.channelId].liveEvents as TLiveData[], ...action.value.liveEvents as TLiveData[]]
                    .filter((video, index, self) =>
                    index === self.findIndex((v) => (
                        v.id === video.id
                    ))
                );
            }
            break;
        case videosActions.APPEND_LIVE_EVENTS:
            if (action.value) state[action.value.channelId].liveEvents = [...state[action.value.channelId].liveEvents as TLiveData[], ...action.value.liveEvents as TLiveData[]];
            break;

        // These cause problems with checking access
        // case videosActions.SET_VIDEO_EMBED_CODE:
        //     if (action.value) {
        //         if (state[action.value.channelId].videos) {
        //             for (let video in state[action.value.channelId].videos) {
        //                 // @ts-ignore
        //                 if (state[action.value.channelId].videos[video].id === action.value.videos[0].id) {
        //                     // @ts-ignore
        //                     state[action.value.channelId].videos[video].embed_code = action.value.videos[0].embed_code;

        //                 }
        //             }
        //         }
        //     }
        //     break;
        // case videosActions.SET_LIVE_EMBED_CODE:
        //     if (action.value) {
        //         if (state[action.value.channelId].liveEvents) {
        //             for (let video in state[action.value.channelId].liveEvents) {
        //                 // @ts-ignore
        //                 if (state[action.value.channelId].liveEvents[video].id === action.value.videos[0].id) {
        //                     // @ts-ignore
        //                     state[action.value.channelId].liveEvents[video].embed_code = action.value.videos[0].embed_code;

        //                 }
        //             }
        //         }
        //     }
        //     break;
        default:
            break;
    }
    return { ...state };
}

const VideosContext = createContext<{ state: TVideosState, dispatch: React.Dispatch<TAction> }>({ state: initialVideosState, dispatch: () => { } });

export { initialVideosState, VideosContext, videosReducer };
export type { TVideoData, TLiveData }
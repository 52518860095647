import Page from 'components/Page';
import React from 'react';
import './Page404.scss';

const Page404: React.FC = () => {
    return(
        <Page className="Page404">
            <h1>Sivua ei löytynyt</h1>
        </Page>
    );
}

export default Page404;
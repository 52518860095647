import React from 'react';
import { IFormElement } from '..';
import './TextArea.scss';

interface TextAreaProps extends IFormElement{

}

const TextArea: React.FC<TextAreaProps> = (props) => {
    return (
        <>
            <label htmlFor={props.name}>{props.title}</label>
            <textarea id={props.name} required={props.required} name={props.name} className='TextArea'>
            </textarea>
        </>
    );
}

export default TextArea;
import React from 'react';

const SafariHeaderStyles: React.FC = () => {
    return(
        <style>
            {`
            .Header{
                transform: translateZ(10px);
            }
            .Header *{
                transform-style: preserve-3d;
            }
            .Header .mobile-menu-button{
                transform: translateZ(15px);
            }
            .Header .SearchBar{
                transform: translateZ(15px);
            }
            .Header .SearchBar .search-icon{
                transform: translateZ(50px);
            }
            .Header .SearchBar .search-suggestions{
                transform: translateZ(10px);
            }
            .Header .SearchBar .suggestion-slide-hide{
                transform: translateZ(10px);
            }
            .Header .SearchBar form {
                transform: translateZ(20px);
            }
            .Header .SearchBar form input{
                transform: translateZ(20px);
            }
            .Header .SearchBar form button{
                transform: translateZ(15px);
            }
            `}
        </style>
    );
}

export default SafariHeaderStyles;
import React, { createContext } from 'react';
import { appActions } from './actions';

type TAction = {
    type: string;
    value: any;
}
type TAppState = {
    message: string | undefined;
}

const initialAppState: TAppState = {
    message: undefined
}

function appReducer(state: TAppState, action: TAction): TAppState {
    switch (action.type){
        case appActions.SET_ALERT_MESSAGE:
            state.message = action.value;
            break;
        default:
            break;
    }
    return {...state};
}

const AppContext = createContext<{state: TAppState, dispatch: React.Dispatch<TAction>}>({state: initialAppState, dispatch: () => {}});

export { initialAppState, AppContext, appReducer };
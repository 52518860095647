const routes: {
    [key: string]: {
        name: string;
        route: string;
        menuLink: boolean;
        withParams?: (params: { [key: string]: string | number }) => string;
    }
} = {
    frontPage: {
        name: "ETUSIVU",
        route: "/",
        menuLink: true
    },
    videosPage: {
        name: "VIDEOKIRJASTO",
        route: "/videos",
        menuLink: true,
        withParams: (params) => `/videos/${params.videoId}`
    },
    watchVideoPage: {
        name: "VIDEO",
        route: "/videos/:videoId",
        menuLink: false
    },
    livePage: {
        name: "LIVELÄHETYKSET",
        route: "/live",
        menuLink: true,
        withParams: (params) => `/live/${params.liveId}`
    },
    watchLivePage: {
        name: "LIVE",
        route: "/live/:liveId",
        menuLink: false
    },
    storePage: {
        name: "LIPPUKAUPPA",
        route: "/store",
        menuLink: true
    },
    contactPage: {
        name: "OTA YHTEYTTÄ",
        route: "/contact",
        menuLink: true
    },
    searchPage: {
        name: "HAKUSIVU",
        route: '/search/:input',
        menuLink: false,
        withParams: (params) => `/search/${params.input}`
    },
    privacyStatementPage: {
        name: "Tietosuojaseloste",
        route: "/privacy",
        menuLink: false
    },
    accessibilityStatementPage: {
        name: "Saavutettavuusseloste",
        route: "/accessibility",
        menuLink: false
    },
    cartPage: {
        name: "OSTOSKORI",
        route: "/cart",
        menuLink: false
    },
    paymentsPage: {
        name: "MAKSAMINEN",
        route: "/payments",
        menuLink: false
    },
    channelsPage: {
        name: "KANAVAT",
        route: "/channels/:channelId",
        withParams: (params) => "/channels/" + params.id,
        menuLink: false
    }
};

const apiVersion = "v1";
const apiRoutes = {
    payments: "/payments",
    videos: "/videos",
    channels: "/channels",
    liveEvents: "/live",
    access: "/access",
    contact: "/contact",
    prices: "/prices",
    images: "/images",
    motds: "/motds",
}
const apiURL = process.env?.NODE_ENV === "development" ? `http://localhost:3001/${apiVersion}` : `https://www.videokanava.fi/api/${apiVersion}`


export {
    routes,
    apiRoutes,
    apiURL
};
import React from 'react';
import { SVGProps, SVGBase } from './SVGTypes';

const MenuIcon: React.FC<SVGProps> = (props) => {
    return (
        <SVGBase ariaLabel="" width={40} height={40} scale={props.scale}>
            <path d="M3,5.5a2.5,2.5,0,0,1,0-5H37a2.5,2.5,0,0,1,0,5Z" />
            <path d="M37,1a2,2,0,0,1,0,4H3A2,2,0,0,1,3,1H37m0-1H3A3,3,0,0,0,3,6H37a3,3,0,0,0,0-6Z" />
            <path d="M3,22.5a2.5,2.5,0,0,1,0-5H37a2.5,2.5,0,0,1,0,5Z" />
            <path d="M37,18a2,2,0,0,1,0,4H3a2,2,0,0,1,0-4H37m0-1H3a3,3,0,0,0,0,6H37a3,3,0,0,0,0-6Z" />
            <path d="M3,39.5a2.5,2.5,0,0,1,0-5H37a2.5,2.5,0,0,1,0,5Z" />
            <path d="M37,35a2,2,0,0,1,0,4H3a2,2,0,0,1,0-4H37m0-1H3a3,3,0,0,0,0,6H37a3,3,0,0,0,0-6Z" />
        </SVGBase>
    );
}

export default MenuIcon;
import { ApiCalls } from "constants/classes/ApiCalls";
import axios from "axios";

export class Payments extends ApiCalls {
    public async getPaymentProviders(): Promise<{ [key: string]: any }> {
        let response: { [key: string]: any } | Error;
        try {
            response = await axios.get(`${this.url}${this.route}`).catch(err => {
                return {
                    status: err.response.status,
                    message: err.response.message
                };
            });
        } catch (error) {
            response = {
                status: 510,
                message: "Unknown error occurred"
            };
        }
        return response;
    }
    public async postPaymentRequest(body: { [key: string]: any }) {
        let response = await axios.post(`${this.url}${this.route}/request`, JSON.stringify(body), {
            headers: {
                "Content-Type": "application/json"
            }
        }).then(response => {
            return {
                status: response.status,
                data: response.data
            }
        }).catch(err => {
            return {
                status: err.response.status,
                data: err.response.data.message
            };
        });
        return response;
    }
    public async postPaymentConfirmation(transactionId: string) {
        let response = await axios.post(`${this.url}${this.route}/confirm`, JSON.stringify({ transactionId }), {
            headers: {
                "Content-Type": "application/json"
            }
        }).then(response => {
            return response.data
        }).catch(err => {
            return {
                status: err.response.status,
                message: err.response.data.message
            };
        });
        return response;
    }
    public async postPaymentCancellation(transactionId: string) {
        await axios({
            url: `${this.url}${this.route}/cancel`,
            method: 'POST',
            data: {
                transactionId
            },
            headers: {
                "Content-Type": "application/json"
            }
        }).then(response => {
            // need to do something here?
        }).catch(err => {
            console.log(err);

        });
    }
}
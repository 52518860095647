import { appActions } from 'contexts/AppContext/actions';
import { AppContext } from 'contexts/AppContext/reducer';
import React, { useEffect, useContext, useRef, useState } from 'react';
import {useHistory} from 'react-router-dom';
import './Alert.scss';

const Alert: React.FC = () => {
    const { state, dispatch } = useContext(AppContext);
    const alertRef = useRef<HTMLDivElement | null>(null);
    const [hideAlert, setHideAlert] = useState<NodeJS.Timeout>();
    const [hideAlertMessage, setHideAlertMessage] = useState<NodeJS.Timeout>();
    const history = useHistory();

    const clearAlert = () => {
        if (hideAlert) clearTimeout(hideAlert);
        if (hideAlertMessage) clearTimeout(hideAlertMessage);
    }
    useEffect(() => {
        if (state.message) {
            if (alertRef.current) {
                alertRef.current.classList.add('display-alert');
            }
            clearAlert();
            setHideAlert(setTimeout(() => {
                if (alertRef.current) {
                    alertRef.current.classList.remove('display-alert');
                }
                setHideAlertMessage(setTimeout(() => {
                    dispatch({ type: appActions.SET_ALERT_MESSAGE, value: undefined });
                }, 500));
            }, 3000));

        }
        return(clearAlert);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state, dispatch]);
    
    useEffect(() => {
        clearAlert();            
        if (alertRef.current) {
            alertRef.current.classList.remove('display-alert');
        }
        dispatch({ type: appActions.SET_ALERT_MESSAGE, value: undefined });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history.length]);

    return (
        <div role="alert" ref={alertRef} className='Alert'>
            <h4>{state.message}</h4>
        </div>
    );
}

export default Alert;
import React, { createContext } from 'react';
import { searchActions } from './actions';

type TAction = {
    type: string;
    value: any[];
}
type TSearchState = {
    searchSuggestions: string[]
}

const initialSearchState: TSearchState = {
    searchSuggestions: []
}
function searchReducer(state: TSearchState, action: TAction): TSearchState {
    switch (action.type){
        case searchActions.SET_SEARCH_SUGGESTIONS:
            state.searchSuggestions = action.value;
            break;
        default:
            break;
    }
    return {...state};
}

const SearchContext = createContext<{state: TSearchState, dispatch: React.Dispatch<TAction>}>({state: initialSearchState, dispatch: () => {}});

export { initialSearchState, SearchContext, searchReducer };
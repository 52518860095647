import Page from 'components/Page';
import PageDescription from 'components/PageDescription';
import React from 'react';
import './PrivacyStatementPage.scss';

const PrivacyStatementPage: React.FC = () => {
    return (
        <Page className="PrivacyStatementPage">
            <PageDescription title="TIETOSUOJASELOSTE">
                <p>Sivustomme ei kerää käyttäjiltä henkilötietoja ja käytämme vain välttämättömiä evästeitä sivuston toiminnan takaamiseksi.</p>
                <p>Mitään käyttäjien tietoja ei jaeta kolmansien osapuolien kanssa.</p>
            </PageDescription>
        </Page>
    );
}

export default PrivacyStatementPage;
import Page from 'components/Page';
import { Payments } from 'constants/classes/Payments';
import { apiRoutes } from 'constants/constants';
import { cartActions } from 'contexts/CartContext/actions';
import { CartContext } from 'contexts/CartContext/reducer';
import React, { useEffect, useContext } from 'react';
import './CancelPaymentPage.scss';

const payments = new Payments(apiRoutes.payments);

const CancelPaymentPage: React.FC = () => {
    const { state, dispatch } = useContext(CartContext);

    const cancelPayment = () => {
        if (state.paymentInfo) {            
            payments.postPaymentCancellation(state.paymentInfo.transactionId);
        }
        else {
            const urlParams = new URLSearchParams(window.location.search);
            const myParam = urlParams.get('checkout-transaction-id');
            if(myParam){
                payments.postPaymentCancellation(myParam);
            }
        }
    }

    useEffect(() => {
        cancelPayment();
        dispatch({ type: cartActions.SET_PAYMENT_INFO, value: undefined });
        dispatch({ type: cartActions.SET_CUSTOMER_EMAIL, value: undefined });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Page className='CancelPaymentPage'>
            <h1>Maksutapahtuma peruutettu</h1>
        </Page>
    );
}

export default CancelPaymentPage;
import { ApiCalls } from "constants/classes/ApiCalls";
import axios from "axios";

export class Videos extends ApiCalls {
    public async getVideos(): Promise<{ [key: string]: any }>{
        let response: {[key: string]: any } | Error;
        try {
            response = await axios.get(`${this.url}${this.route}`).catch(err => {
                return {
                    status: err.response.status,
                    message: err.response.message
                };
            });
        } catch (error) {
            response = {
                status: 510,
                message: "Unknown error occurred"
            };
        }
        return response;
    }

    public async getVideo(videoId: string): Promise<{ [key: string]: any }>{
        let response: {[key: string]: any } | Error;
        try {
            response = await axios.get(`${this.url}${this.route}/${videoId}`).catch(err => {
                return {
                    status: err.response.status,
                    message: err.response.message
                };
            });
        } catch (error) {
            response = {
                status: 510,
                message: "Unknown error occurred"
            };
        }
        return response;
    }

}
import React, { createContext } from 'react';
import { channelsActions } from './actions';

type TAction = {
    type: string;
    value: any;
}

type TChannel = {
    id: number;
    title: string;
    description: {
        type: "paragraph" | "subtitle",
        text: string;
    }[],
    image_id: number;
    pricing_scheme_id: number | null;
}

type TChannelsState = {
    channels: TChannel[],
    searchedAllChannels: boolean
}

const initialChannelsState: TChannelsState = {
    channels: [],
    searchedAllChannels: false
}

function channelsReducer(state: TChannelsState, action: TAction): TChannelsState {
    switch (action.type){
        case channelsActions.SET_ALL_CHANNELS:
            state.channels = action.value;
            state.searchedAllChannels = true;
            break;
        default:
            break;
    }
    return {...state};
}

const ChannelsContext = createContext<{state: TChannelsState, dispatch: React.Dispatch<TAction>}>({state: initialChannelsState, dispatch: () => {}});

export { initialChannelsState, ChannelsContext, channelsReducer };
export type {TChannel}
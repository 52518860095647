import LoadingIndicator from 'components/LoadingIndicator';
import Page from 'components/Page';
import { Payments } from 'constants/classes/Payments';
import { apiRoutes } from 'constants/constants';
import { cartActions } from 'contexts/CartContext/actions';
import { CartContext } from 'contexts/CartContext/reducer';
import React, {useContext, useEffect, useState} from 'react';
import './SuccessfulPaymentPage.scss';

const payments = new Payments(apiRoutes.payments);

const SuccessfulPaymentPage: React.FC = () => {
    const [confirmed, setConfirmed] = useState(false);
    const [paid, setPaid] = useState(false);
    const [message, setMessage] = useState("Maksua vahvistetaan");
    const { dispatch} = useContext(CartContext);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const myParam = urlParams.get('checkout-transaction-id');
        if(myParam){
            payments.postPaymentConfirmation(myParam).then(response => {
                setMessage(response.message);
                setConfirmed(true);
                setPaid(true);
                if(response.status === 200){
                    dispatch({type:cartActions.EMPTY_CART});
                    dispatch({type: cartActions.SET_PAYMENT_INFO, value: undefined});
                    dispatch({type: cartActions.SET_CUSTOMER_EMAIL, value: undefined});
                }
            });

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return(
        <Page className='SuccessfulPaymentPage'>
            {!confirmed && !paid ? 
            <div className='confirmation-container'><h1>{message}</h1><LoadingIndicator /></div> : !paid ? 
            <div className='confirmation-container'><h1>{message}</h1></div> : 
            <div className='confirmation-container'><h1>{message}</h1></div>}
        </Page>
    );
}

export default SuccessfulPaymentPage;
import Page from 'components/Page';
import VideoContainer from 'components/VideoContainer';
import React from 'react';
import './WatchVideoPage.scss';

const WatchVideoPage: React.FC = () => {
    return (
        <Page className="WatchVideoPage">
            <VideoContainer type="videos" />
        </Page>
    );
}

export default WatchVideoPage;
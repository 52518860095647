import Button from 'components/Button';
import CartContainer from 'components/CartContainer';
import { Form, Input } from 'components/FormElements';
import LoadingIndicator from 'components/LoadingIndicator';
import Modal from 'components/Modal';
import PricingForm from 'components/PricingForm';
import { Access } from 'constants/classes/Access';
import { LiveEvents } from 'constants/classes/LiveEvents';
import { Prices } from 'constants/classes/Prices';
import { Videos } from 'constants/classes/Videos';
import { apiRoutes, routes } from 'constants/constants';
import { appActions } from 'contexts/AppContext/actions';
import { AppContext } from 'contexts/AppContext/reducer';
import { storeActions } from 'contexts/StoreContext/actions';
import { StoreContext } from 'contexts/StoreContext/reducer';
import { videosActions } from 'contexts/VideosContext/actions';
import { TLiveData, TVideoData, VideosContext } from 'contexts/VideosContext/reducer';
import React, { useContext, useEffect, useRef, useState, createRef } from 'react';
import { useParams } from 'react-router-dom';
import Image from 'components/Image';
import './VideoContainer.scss';
import { ChannelsContext } from 'contexts/ChannelsContext/reducer';
import { channelsActions } from 'contexts/ChannelsContext/actions';
import { Channels } from 'constants/classes/Channels';
import Link from 'components/Link';
import FreeContentStamp from 'components/FreeContentStamp';
type VideoCodeFormProps = {
    type: 'videos' | 'live';
    videoId: number;
    setAccess: React.Dispatch<React.SetStateAction<Access | null>>;
    setEmbedCode: (status: number, data?: { embed_code: string, chat?: 0 | 1 }, message?: string) => void;
    fetching: boolean;
    isFetching: React.Dispatch<React.SetStateAction<boolean>>
}

const VideoCodeForm: React.FC<VideoCodeFormProps> = (props) => {
    const formRef = createRef<HTMLFormElement>();
    const postCode = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!props.fetching) {
            let formData = new FormData(e.target as HTMLFormElement);
            props.setAccess(new Access(Object.fromEntries(formData).code as string, props.type, props.videoId, props.setEmbedCode));
            props.isFetching(true);
        }
    }
    return (
        <Form ref={formRef} method="POST" action={apiRoutes.access} onSubmit={postCode}>
            <Input minLength={6} maxLength={6} required={true} type="text" name="code" title="Lippukoodi" formRef={formRef} />
            <Button type="submit-regular" color="main-color">{props.fetching ? <LoadingIndicator /> : "Katso!"}</Button>
        </Form>
    );
}

type VideoContentProps = {
    data: TVideoData | TLiveData;
    containerRef: React.MutableRefObject<HTMLDivElement | null>;
    type: "videos" | "live";
    setChat: React.Dispatch<React.SetStateAction<boolean>>;
    setDirection: React.Dispatch<React.SetStateAction<"horizontal" | "vertical">>;
    direction: "horizontal" | "vertical";
    chat: boolean;
}

const VideoContent: React.FC<VideoContentProps> = (props) => {
    const playerContainerRef = useRef<HTMLIFrameElement | null>(null);
    const [priceModal, setPriceModal] = useState<JSX.Element | null>(null);
    const [access, setAccess] = useState<Access | null>(null);
    const appDispatch = useContext(AppContext).dispatch;
    const channelsState = useContext(ChannelsContext).state;
    const store = useContext(StoreContext);
    const [embedCode, setCode] = useState<string | null>(null);
    const [chatEmbedCode, setChatEmbedCode] = useState<string | null>(null);
    const [fetching, isFetching] = useState(false);

    const getScreenDirection = () => {
        if (playerContainerRef.current) {
            let width = playerContainerRef.current.getBoundingClientRect().width;
            let height = width * 9 / 16;
            playerContainerRef.current.style.height = height + 'px';
            if (props.chat && width < 500 && props.direction === 'horizontal') {
                props.setDirection('vertical');
            } else if (props.chat && width > 900 && props.direction === 'vertical') {
                props.setDirection('horizontal');
            }
        }
    }

    const setEmbedCode = (status: number, data?: { embed_code: string, chat?: 0 | 1 }, message?: string) => {
        if (status === 200 && data) {
            setCode(data.embed_code);

            if (data.chat) {
                setChatEmbedCode(data.embed_code);
                props.setChat(true);
            }
        } else {
            appDispatch({
                type: appActions.SET_ALERT_MESSAGE,
                value: message
            })
        }
        isFetching(false);

    }

    const setCartModal = (hide?: boolean) => {
        if (hide) {
            setPriceModal(null);
        } else {
            setPriceModal(<Modal close={() => setPriceModal(null)}>
                <CartContainer />
            </Modal>)
        }
    }

    const setModal = async () => {
        if (!store.state.prices[props.data.pricing_scheme_id]) {
            let prices = new Prices(apiRoutes.prices);
            await prices.getPricesWithId(props.data.pricing_scheme_id).then((result) => {
                if (result.status === 200) {
                    store.dispatch({ type: storeActions.ADD_PRICING, value: result.data });
                } else {
                    console.log(result);
                }
            });
        }
        if (store.state.prices[props.data.pricing_scheme_id]) {
            setPriceModal(<Modal close={() => setPriceModal(null)}>
                <PricingForm setModal={setCartModal} channelId={props.data.channel_id} videoId={props.data.id} type={props.type} data={store.state.prices[props.data.pricing_scheme_id]} />
            </Modal>);
        }

    }
    const getChannelImageId = (): number => {
        for (let channel of channelsState.channels) {
            if (channel.id === props.data.channel_id) {
                return channel.image_id;
            }
        }
        return 1;
    }

    const renderViewTime = (): JSX.Element | null => {
        return (
            props.data.end_date && props.data.active ? <h4>Esitys on katsottavissa {new Date(props.data.end_date * 1000).toLocaleString()} saakka</h4> :
                props.data.end_date && !props.data.active ? <h4>Tapahtuman lipunmyynti loppuu {new Date(props.data.end_date * 1000).toLocaleString()}</h4> : null
        );
    }

    const renderInfoContainer = (): JSX.Element => {
        return (
            <div className="video-info">
                <div className="info-container">
                    <div className="video-title">
                        <Image id={getChannelImageId()} />
                        <div className="title-container">
                            <h1>{props.data.title}</h1>
                            <p>{props.data.channel_title}</p>
                            {props.data.start_date ? <p>{new Date(props.data.start_date * 1000).toLocaleString()}</p> : null}
                        </div>
                        {props.data.pricing_scheme_id ? <Button type="button-regular" color="tertiary-color" onClick={setModal}>Liput</Button> : <FreeContentStamp />}

                    </div>
                    <div className="video-description">
                        {renderViewTime()}
                        {props.data.description.map((description, index) =>
                            description.type === "subtitle" ?
                                <h2 key={index}>{description.text}</h2> : <p key={index}>{description.text}</p>
                        )}
                        <Link color={"link-dark"} to={routes.channelsPage.withParams ? routes.channelsPage.withParams({ id: props.data.channel_id }) : "#"}>Lisää videoita kanavalta {props.data.channel_title}</Link>
                    </div>
                </div>
            </div>
        );
    }

    useEffect(() => {
        window.addEventListener('resize', getScreenDirection);
        getScreenDirection();

        return () => {
            window.removeEventListener('resize', getScreenDirection);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.direction, props.chat]);

    useEffect(() => {
        if (props.data && props.data.embed_code) {
            setCode(props.data.embed_code);
            if ('chat' in props.data && props.data.chat) {
                setChatEmbedCode(props.data.embed_code);
                props.setChat(true);
            }
        }
        return () => {
            if (access) access.disconnect();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [access]);

    if (chatEmbedCode && embedCode) {
        return (
            <>
                {priceModal}
                <div className="player-container" ref={playerContainerRef}>
                    <Image id={props.data.image_id} />
                    <iframe
                        src={`https://player.vimeo.com/video/${embedCode}?h=85653fc210&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`}
                        className="video-player"
                        frameBorder="0"
                        allow="autoplay; fullscreen; picture-in-picture"
                        allowFullScreen
                        title={props.data.title} />
                </div>
                {props.direction === "horizontal" ? renderInfoContainer() : <iframe title="chat" src={`https://vimeo.com/live-chat/${chatEmbedCode}/`} className="chat-embed" frameBorder="0" />}

                {props.direction === "horizontal" ?
                    <iframe title="chat" src={`https://vimeo.com/live-chat/${chatEmbedCode}/`} className="chat-embed" frameBorder="0" /> : renderInfoContainer()}
            </>
        );
    } else if (embedCode) {
        return (
            <>
                {priceModal}
                <div className="player-container" ref={playerContainerRef}>
                    <Image id={props.data.image_id} />
                    <iframe
                        src={`https://player.vimeo.com/video/${embedCode}?h=85653fc210&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`}
                        className="video-player"
                        frameBorder="0"
                        allow="autoplay; fullscreen; picture-in-picture"
                        allowFullScreen
                        title={props.data.title} />
                </div>
                {renderInfoContainer()}
            </>
        );
    }
    return (
        <>
            {priceModal}
            <div className="player-container" ref={playerContainerRef}>
                <Image id={props.data.image_id} />
                {props.data.active ? <div className="form-container">
                    <VideoCodeForm isFetching={isFetching} fetching={fetching} type={props.type} videoId={props.data.id} setAccess={setAccess} setEmbedCode={setEmbedCode} />
                </div> : null}

            </div>
            {renderInfoContainer()}
        </>
    );
}

type VideoContainerProps = {
    type: 'videos' | 'live';
}

const VideoContainer: React.FC<VideoContainerProps> = (props) => {
    const containerRef = useRef<HTMLDivElement | null>(null);
    const [loading, isLoading] = useState(true);
    const [chat, isChat] = useState(false);
    const [videoData, setVideoData] = useState<TVideoData | TLiveData | null>(null);
    const { videoId } = useParams() as { videoId: string };
    const { liveId } = useParams() as { liveId: string };
    const { state, dispatch } = useContext(VideosContext);
    const channelsContext = useContext(ChannelsContext);
    const [direction, setDirection] = useState<"vertical" | "horizontal">("horizontal");

    const findVideoData = () => {
        for (let channel in state) {
            if (state[channel].videos) {
                // @ts-ignore no its not undefined...
                for (let video of state[channel].videos) {
                    if (video.id === Number(videoId)) {
                        isLoading(false);
                        setVideoData(video);
                        return;
                    }
                }
            }
        }
        getVideoData();
    }

    const findLiveData = () => {
        for (let channel in state) {
            if (state[channel].liveEvents) {
                // @ts-ignore no its not undefined...
                for (let video of state[channel].liveEvents) {
                    if (video.id === Number(liveId)) {
                        isLoading(false);
                        setVideoData(video);
                        return;
                    }
                }
            }
        }
        getLiveData();
    }

    const getChannels = async () => {
        const channels = new Channels(apiRoutes.channels);
        const response = await channels.getChannels();
        if (response.status === 200) {
            channelsContext.dispatch({ type: channelsActions.SET_ALL_CHANNELS, value: response.data });
        } else {
            console.log(response);
        }

    }

    const getVideoData = async () => {
        let videos = new Videos(apiRoutes.videos);
        const response = await videos.getVideo(videoId);

        if (response.status === 200) {
            dispatch(
                {
                    type: videosActions.APPEND_VIDEOS,
                    value:
                    {
                        channelId: response.data[0].channel_id,
                        videos: response.data
                    }
                }
            );
            setVideoData(response.data[0]);
            isLoading(false);
            getChannels();
            return;
        } else {
            console.log(response);
        }
        isLoading(false);
        setVideoData(null);
    }

    const getLiveData = async () => {
        let liveEvents = new LiveEvents(apiRoutes.liveEvents);
        const response = await liveEvents.getLiveEvent(liveId);

        if (response.status === 200) {
            dispatch(
                {
                    type: videosActions.APPEND_LIVE_EVENTS,
                    value:
                    {
                        channelId: response.data[0].channel_id,
                        liveEvents: response.data
                    }
                }
            );
            setVideoData(response.data[0]);
            if (response.data[0].chat) {
                isChat(true);
            }
            isLoading(false);
            getChannels();
            return;
        } else {
            console.log(response);
        }
        isLoading(false);
        setVideoData(null);
    }


    useEffect(() => {
        if (!videoData) isLoading(true);
        if (videoId) findVideoData();
        else if (liveId) findLiveData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state]);

    return (
        <div ref={containerRef} className={(chat ? 'VideoContainer chat ' : 'VideoContainer ') + direction}>
            {videoData && !loading ?
                <VideoContent direction={direction} setDirection={setDirection} setChat={isChat} chat={chat} type={props.type} containerRef={containerRef} data={videoData} />
                : loading ? <LoadingIndicator /> : null}
        </div>
    );
}

export default VideoContainer;
import React, {useState, useEffect} from 'react';

type SVGProps = {
    scale: number;
    color?: string;
}

type SVGBaseProps = {
    height: number;
    ariaLabel: string;
    width: number;
    scale?: number;
    className?: string;
    fill?:string;
}

const calcScale = (scale: number | undefined): number | undefined => {
    if (window.innerWidth > 1920 && scale) {
        return window.innerWidth / 1920 * scale;
    }
    return scale;
}

const SVGBase: React.FC<SVGBaseProps> = (props) => {
    const [scale, setScale] = useState(calcScale(props.scale));

    const changeScale = () => {
        setScale(calcScale(props.scale));
    }
    useEffect(() => {
        if(props.scale){
            window.addEventListener('resize', changeScale);
            return(() => {
                window.removeEventListener('resize', changeScale);
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <svg xmlns="http://www.w3.org/2000/svg" 
        className={props.className}
        width={props.width} 
        height={props.height} 
        fill={props.fill}
        aria-label={props.ariaLabel}
        style={{transform: `scale(${scale})`}} 
        viewBox={`0 0 ${props.width} ${props.height}`}>
        {props.children}</svg>
    );
}
export { SVGBase };
export type { SVGProps }
import React from 'react';
import { SVGProps,SVGBase } from './SVGTypes';

const Logo: React.FC<SVGProps> = (props) => {
    return (
        <SVGBase ariaLabel="" width={40} height={40} scale={props.scale}>
            <path
                d="M28.82,19.55a9.27,9.27,0,0,1-.38,3.33C38.83,10.56,31.9.79,31.9.79,30.69-.81,30,.52,30,.52a15.55,15.55,0,0,1-7.09,11A9.24,9.24,0,0,1,28.82,19.55Z"
                style={{ fill: props.color }} />
            <path
                d="M19.49,11.16a9.07,9.07,0,0,1,3.4.37C10.41,1.38.77,8.48.77,8.48-.81,9.72.53,10.41.53,10.41a15.57,15.57,0,0,1,11.08,6.82A9.15,9.15,0,0,1,19.49,11.16Z"
                style={{ fill: props.color }}
            />
            <path
                d="M11.26,20.52a9.3,9.3,0,0,1,.38-3.34C1.62,29.65,8.7,39.23,8.7,39.23c1.24,1.58,1.93.24,1.93.24a15.64,15.64,0,0,1,6.55-10.94A9.24,9.24,0,0,1,11.26,20.52Z"
                style={{ fill: props.color }}
            /><path
                d="M39.47,29.64a15.58,15.58,0,0,1-11-6.75,9.12,9.12,0,0,1-11.31,5.64c12.47,10.14,22.11,3,22.11,3C40.81,30.33,39.47,29.64,39.47,29.64Z"
                style={{ fill: props.color }}
            />
        </SVGBase>
    );
}

export default Logo;
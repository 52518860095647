import React from 'react';
import './VideoCard.scss';
import { TLiveData, TVideoData } from "contexts/VideosContext/reducer";
import { routes } from 'constants/constants';
import Link from 'components/Link';
import Image from 'components/Image';
import FreeContentStamp from 'components/FreeContentStamp';

type VideoCardProps = {
    data: TVideoData | TLiveData;
    time: string | null;
    type: "live" | "videos";
}

const VideoCard: React.FC<VideoCardProps> = (props) => {
    const getVideoRoute = (): string => {
        if (routes.videosPage.withParams && routes.livePage.withParams) {
            return props.type === "live" ? routes.livePage.withParams({ liveId: props.data.id }) : routes.videosPage.withParams({ videoId: props.data.id });
        }
        return "";
    }
    return (
        <Link to={getVideoRoute()}>
            <div className='VideoCard'>
                <div className="image-container">
                    <Image id={props.data.image_id} />
                    {!props.data.pricing_scheme_id ? <FreeContentStamp /> : null}
                </div>
                <div className="info-container">
                    <h2>{props.data.title}</h2>
                    <p>{props.data.channel_title}</p>
                    {props.time ? <p className="time">{props.time}</p> : null}
                </div>
            </div>
        </Link>
    );
}

export default VideoCard;
import ChannelDropDown from 'components/ChannelDropDown';
import LoadingIndicator from 'components/LoadingIndicator';
import Page from 'components/Page';
import PageDescription from 'components/PageDescription';
import { Channels } from 'constants/classes/Channels';
import { apiRoutes, routes } from 'constants/constants';
import { channelsActions } from 'contexts/ChannelsContext/actions';
import { ChannelsContext } from 'contexts/ChannelsContext/reducer';
import React, { useContext, useEffect, useState } from 'react';
import './LivePage.scss';

const LivePage: React.FC = () => {
    const { state, dispatch } = useContext(ChannelsContext);
    const [dropDownClickedId, setDropDownClickedId] = useState<number | null>(null);
    const [loading, isLoading] = useState(false);

    const getChannels = async () => {
        isLoading(true);
        const channels = new Channels(apiRoutes.channels);
        const response = await channels.getChannels();

        if (response.status === 200) {
            dispatch({ type: channelsActions.SET_ALL_CHANNELS, value: response.data });
        } else {
            console.log(response);
        }
        isLoading(false);
    }

    useEffect(() => {
        if (!state.searchedAllChannels) {
            getChannels();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <Page className="LivePage">
            <PageDescription title={routes.livePage.name}>
                <p>Tällä sivulla on kaikki sivuston livelähetykset ja niiden tallenteet kanavakohtaisesti jaoteltuna.</p>
            </PageDescription>
            {loading ? <LoadingIndicator /> :
                state.channels.map((channel, index) => 
                    <ChannelDropDown key={index} dropDownClickedId={dropDownClickedId} setDropDownClickedId={setDropDownClickedId} data={channel} type="live" />
                )}
        </Page>
    );
}

export default LivePage;
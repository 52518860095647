import React, { useRef, useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import Button from 'components/Button';
import { LiveEvents } from 'constants/classes/LiveEvents';
import { apiRoutes, routes } from 'constants/constants';
import Image from 'components/Image';
import { MOTDS } from 'constants/classes/MOTDS';
import LoadingIndicator from 'components/LoadingIndicator';
import { MOTDContext, TMessage } from 'contexts/MOTDContext/reducer';
import { MOTDActions } from 'contexts/MOTDContext/actions';


interface MessageProps extends TMessage {
    className?: "current-message";
}

const MOTD: React.FC<MessageProps> = (props) => {
    const history = useHistory();

    const goToLink = () => {
        if (props.link) {
            history.push(props.link);
        }
    }
    return (
        <div
            className={props.className ? "Message " + props.className : "Message"}>
            <Image id={props.image_id} />
            <div className="text-content">
                <span className="title-2">{props.title}</span>
                <p>{props.description}</p>
                {props.link ? <Button type="button-regular" color="main-color" onClick={goToLink}>Lue lisää</Button> : null}
            </div>
        </div>
    );
}

var globalMessageIndex = 0;
var messageInterval: NodeJS.Timeout; // needs to be global to be passed correctly to the scope
const MOTDInterval = 7000;

const MessagesOfTheDay: React.FC = () => {
    const motdsRef = useRef<HTMLDivElement | null>(null);
    const [currentMessageIndex, setCurrentMessageIndex] = useState(globalMessageIndex);
    const {state, dispatch} = useContext(MOTDContext);

    const setTop = () => {
        let header = document.querySelector('header');
        if (motdsRef.current) {
            if (window.innerHeight <= 500) {
                motdsRef.current.style.top = '0px';
            }
            else if (header) {
                motdsRef.current.style.top = header.getBoundingClientRect().height + 'px';
            }
        }
    }

    // this might not seem optimal, but in case carousel is implemented in future, keep this for now
    const getMessageIndexClass = (index: number): "current-message" | undefined => {
        if (index === currentMessageIndex) return "current-message";
        return undefined;
    }

    const changeMessage = () => {
        if (globalMessageIndex === state.messages.length - 1) {
            globalMessageIndex = 0;
            setCurrentMessageIndex(globalMessageIndex);
        } else {
            setCurrentMessageIndex(++globalMessageIndex);
        }
    }

    const indicatorClicked = (index: number) => {
        globalMessageIndex = index;
        clearInterval(messageInterval);
        messageInterval = setInterval(changeMessage, MOTDInterval);
        setCurrentMessageIndex(index);
    }
    const getMOTDS = async () => {
        let motds = await new MOTDS(apiRoutes.motds);
        let response = await motds.getMOTDS();
        let nextLive = await getNextLiveEvent();
        if(response.data){
            if(nextLive){
                dispatch({type: MOTDActions.SET_ALL_MOTDS, value: [...response.data, nextLive]});
                dispatch({type: MOTDActions.SEARCHED_NEXT_LIVE_EVENT, value: true});
            } else {                
                dispatch({type: MOTDActions.SET_ALL_MOTDS, value: response.data});
            }
        } else if (nextLive){
            dispatch({type: MOTDActions.SET_ALL_MOTDS, value: nextLive});
            dispatch({type: MOTDActions.SEARCHED_NEXT_LIVE_EVENT, value: true});
        } else {
            console.log(response);
        }
    }
    const getNextLiveEvent = async (): Promise<TMessage | undefined> => {
        let live = await new LiveEvents(apiRoutes.liveEvents + '/next');
        let response = await live.getNextLiveEvent()
        if (response.data && response.data.length > 0) {
            return {
                title: "Tulossa: " + response.data[0].title,
                description: new Date(response.data[0].start_date as number * 1000).toLocaleString(),
                image_id: response.data[0].image_id,
                link: routes.livePage.route + "/" + response.data[0].id
            }
        } else return undefined;
    }
    useEffect(() => {
        window.addEventListener('resize', setTop);
        if (state.messages.length > 1) messageInterval = setInterval(changeMessage, MOTDInterval);
        setTop();
        return (() => {
            window.removeEventListener('resize', setTop);
            clearInterval(messageInterval);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.messages]);

    useEffect(() => {
        if(state.messages.length === 0 || !state.searchedNextLiveEvent){
            getMOTDS();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    if (state.messages.length < 1) {
        return <div className="MOTDS">
            <LoadingIndicator />
        </div>;
    }

    return (
        <div ref={motdsRef} className="MOTDS">
            {state.messages.map((message, index) => {
                return <MOTD
                    key={index}
                    className={getMessageIndexClass(index)}
                    title={message.title}
                    description={message.description}
                    image_id={message.image_id}
                    link={message.link} />
            }
            )}
            <div className="carousel-indicators">
                {state.messages.map((message, index) => {
                    return state.messages.length <= 1 ? null :
                        <Button
                            key={index}
                            type="button-no-content"
                            ariaLabel={"Viestikaruselli " + (index + 1)}
                            color={index === currentMessageIndex ? "secondary-color" : "tertiary-color"}
                            onClick={() => indicatorClicked(index)} />
                })}
            </div>
        </div>
    )
}

export default MessagesOfTheDay;
import React from 'react';
import { SVGProps, SVGBase } from './SVGTypes';

interface SearchIconProps extends SVGProps {
    hidden: boolean;
}

const SearchIcon: React.FC<SearchIconProps> = (props) => {
    return (
        <SVGBase 
        className={props.hidden ? 'search-icon search-input-entered' : "search-icon"} 
        width={27} 
        height={27}
        ariaLabel="" 
        fill="none">
            <circle cx="18" cy="9" r="7" stroke="black" strokeWidth="4" />
            <line x1="14.1422" y1="12.7071" x2="0.707137" y2="26.1421" stroke="black" strokeWidth="4" />
        </SVGBase>
    );
}

export default SearchIcon;
import { ApiCalls } from "constants/classes/ApiCalls";
import axios from "axios";

export class Channels extends ApiCalls {
    public async getChannels(): Promise<{ [key: string]: any }> {
        let response: { [key: string]: any } | Error;
        try {
            response = await axios.get(`${this.url}${this.route}`).catch(err => {
                return {
                    status: err.response.status,
                    message: err.response.message
                };
            });
        } catch (error) {
            response = {
                status: 510,
                message: "Unknown error occurred"
            };
        }
        return response;
    }

    public async getVideosFromChannel(channelId: number): Promise<{ [key: string]: any }> {
        let response: { [key: string]: any } | Error;
        try {
            response = await axios.get(`${this.url}${this.route}/videos/${channelId}`).catch(err => {
                return {
                    status: err.response.status,
                    message: err.response.message
                };
            });
        } catch (error) {
            response = {
                status: 510,
                message: "Unknown error occurred"
            };
        }
        return response;
    }

    public async getLiveEventsFromChannel(channelId: number): Promise<{ [key: string]: any }> {
        let response: { [key: string]: any } | Error;
        try {
            response = await axios.get(`${this.url}${this.route}/live/${channelId}`).catch(err => {
                return {
                    status: err.response.status,
                    message: err.response.message
                };
            });
        } catch (error) {
            response = {
                status: 510,
                message: "Unknown error occurred"
            };
        }
        return response;
    }
}
import React, { useReducer, useEffect } from 'react';
import './App.scss';

import { BrowserRouter as Router, Switch, Route, useHistory } from 'react-router-dom';

import FrontPage from 'pages/FrontPage';
import { routes } from 'constants/constants';
import SearchPage from 'pages/SearchPage';
import VideosPage from 'pages/VideosPage';
import LivePage from 'pages/LivePage';
import StorePage from 'pages/StorePage';
import ContactPage from 'pages/ContactPage';
import CartPage from 'pages/CartPage';
import PrivacyStatementPage from 'pages/PrivacyStatementPage';
import AccessibilityStatementPage from 'pages/AccessibilityStatementPage';
import Page404 from 'pages/Page404';
import { CartContext, cartReducer, initialCartState } from 'contexts/CartContext/reducer';
import { initialVideosState, videosReducer, VideosContext } from 'contexts/VideosContext/reducer';
import { ChannelsContext, channelsReducer, initialChannelsState } from 'contexts/ChannelsContext/reducer';
import WatchVideoPage from 'pages/WatchVideoPage';
import WatchLivePage from 'pages/WatchLivePage';
import { AppContext, appReducer, initialAppState } from 'contexts/AppContext/reducer';
import { initialSearchState, SearchContext, searchReducer } from 'contexts/SearchContext/reducer';
import { initialStoreState, StoreContext, storeReducer } from 'contexts/StoreContext/reducer';
import { ImagesContext, imagesReducer, initialImagesState } from 'contexts/ImagesContext/reducer';
import PaymentsPage from 'pages/PaymentsPage';
import CancelPaymentPage from 'pages/CancelPaymentPage';
import SuccessfulPaymentPage from 'pages/SuccessfulPaymentPage';
import ChannelsPage from 'pages/ChannelsPage';
import { initialMOTDState, MOTDContext, MOTDReducer } from 'contexts/MOTDContext/reducer';

const App: React.FC = () => {
  const [cartState, cartDispatch] = useReducer(cartReducer, initialCartState);
  const [videosState, videosDispatch] = useReducer(videosReducer, initialVideosState);
  const [channelsState, channelsDispatch] = useReducer(channelsReducer, initialChannelsState);
  const [appState, appDispatch] = useReducer(appReducer, initialAppState);
  const [searchState, searchDispatch] = useReducer(searchReducer, initialSearchState);
  const [storeState, storeDispatch] = useReducer(storeReducer, initialStoreState);
  const [imagesState, imagesDispatch] = useReducer(imagesReducer, initialImagesState);
  const [MOTDState, MOTDDispatch] = useReducer(MOTDReducer, initialMOTDState);
  
  return (
    <div className="App">
      <AppContext.Provider value={{ state: appState, dispatch: appDispatch }}>
        <StoreContext.Provider value={{ state: storeState, dispatch: storeDispatch }}>
          <CartContext.Provider value={{ state: cartState, dispatch: cartDispatch }}>
            <ImagesContext.Provider value={{ state: imagesState, dispatch: imagesDispatch }}>
              <VideosContext.Provider value={{ state: videosState, dispatch: videosDispatch }}>
                <ChannelsContext.Provider value={{ state: channelsState, dispatch: channelsDispatch }}>
                  <SearchContext.Provider value={{ state: searchState, dispatch: searchDispatch }}>
                    <Router>
                      <Switch>
                        <Route path={routes.searchPage.route}>
                          <SearchPage />
                        </Route>

                        <Route path={routes.watchVideoPage.route}>
                          <WatchVideoPage />
                        </Route>

                        <Route path={routes.watchLivePage.route}>
                          <WatchLivePage />
                        </Route>

                        <Route path={routes.videosPage.route}>
                          <VideosPage />
                        </Route>

                        <Route path={routes.channelsPage.route}>
                          <ChannelsPage />
                        </Route>

                        <Route path={routes.livePage.route}>
                          <LivePage />
                        </Route>

                        <Route path={routes.storePage.route}>
                          <StorePage />
                        </Route>

                        <Route path={routes.contactPage.route}>
                          <ContactPage />
                        </Route>

                        <Route path={routes.privacyStatementPage.route}>
                          <PrivacyStatementPage />
                        </Route>

                        <Route path={routes.accessibilityStatementPage.route}>
                          <AccessibilityStatementPage />
                        </Route>

                        <Route path={routes.cartPage.route}>
                          <CartPage />
                        </Route>

                        <Route path={routes.paymentsPage.route + "/cancel"}>
                          <CancelPaymentPage />
                        </Route>

                        <Route path={routes.paymentsPage.route + "/success"}>
                          <SuccessfulPaymentPage />
                        </Route>

                        <Route path={routes.paymentsPage.route}>
                          <PaymentsPage />
                        </Route>

                        <MOTDContext.Provider value={{ state: MOTDState, dispatch: MOTDDispatch }}>
                          <Route path={routes.frontPage.route} exact>
                            <FrontPage />
                          </Route>
                        </MOTDContext.Provider>

                        <Route path="/">
                          <Page404 />
                        </Route>
                      </Switch>
                    </Router>
                  </SearchContext.Provider>
                </ChannelsContext.Provider>
              </VideosContext.Provider>
            </ImagesContext.Provider>
          </CartContext.Provider>
        </StoreContext.Provider>
      </AppContext.Provider>
    </div>
  );
}

export default App;

import React from 'react';
import './FreeContentStamp.scss';

const FreeContentStamp: React.FC = () => {
    return(
        <div className='FreeContentStamp'>
            <span>ILMAINEN</span>
        </div>
    );
}

export default FreeContentStamp;
import React from 'react';
import "./Link.scss";
import { useHistory } from 'react-router-dom';

type LinkProps = {
    to: string;
    color?: "link-light" | "link-dark";
    onClick?: () => void;
    target?: '_blank';
    className?: string;
    tabIndex?: number;
    ariaLabel?: string;
}

const Link: React.FC<LinkProps> = (props) => {
    const history = useHistory();

    const handleClick = (e: React.MouseEvent) => {
        if (!props.target) {
            e.preventDefault();
            history.push(props.to);
        }
        if (props.onClick) props.onClick();
    }
    let className = 'Link';
    if(props.className){
        className += ' ' + props.className;
    }
    if(props.color){
        className += ' ' + props.color;
    }

    return (
        <a
            className={className}
            target={props.target}
            onClick={handleClick}
            href={props.to}
            aria-label={props.ariaLabel}
            tabIndex={props.tabIndex}>
            {props.children}
        </a>
    );
}

export default Link;
import React, { useEffect, useContext, createRef, useState } from 'react';
import Button from 'components/Button';
import TermsOfSale from 'components/TermsOfSale';
import { Channels } from 'constants/classes/Channels';
import { Payments } from 'constants/classes/Payments';
import { Prices } from 'constants/classes/Prices';
import { apiRoutes, routes } from 'constants/constants';
import { formatTimeWithDays} from 'constants/functions';
import { cartActions } from 'contexts/CartContext/actions';
import { CartContext, TTicketType } from 'contexts/CartContext/reducer';
import { channelsActions } from 'contexts/ChannelsContext/actions';
import { ChannelsContext } from 'contexts/ChannelsContext/reducer';
import { storeActions } from 'contexts/StoreContext/actions';
import { StoreContext } from 'contexts/StoreContext/reducer';
import { videosActions } from 'contexts/VideosContext/actions';
import { TLiveData, TVideoData, VideosContext } from 'contexts/VideosContext/reducer';
import { Form, Input } from 'components/FormElements';
import CheckBox from 'components/FormElements/CheckBox';
import Link from 'components/Link';
import LoadingIndicator from 'components/LoadingIndicator';
import Modal from 'components/Modal';
import './CartContainer.scss';
import { useHistory } from 'react-router-dom';
import { AppContext } from 'contexts/AppContext/reducer';
import { appActions } from 'contexts/AppContext/actions';
const payments = new Payments(apiRoutes.payments);


type CartRowProps = {
    id: string;
    type: TTicketType;
    formRef: React.RefObject<HTMLFormElement>;
}

interface TicketProps extends CartRowProps {
    pricingId: number;
}
const Ticket: React.FC<TicketProps> = (props) => {
    const { state, dispatch } = useContext(CartContext);
    const storeState = useContext(StoreContext).state;
    let amount = state.tickets[props.type][props.id][props.pricingId];

    const findTicketInfo = (): { price: number, users: number, validTime: number } => {
        for (let prices in storeState.prices) {
            for (let price of storeState.prices[prices]) {
                if (price.pricing_id === props.pricingId) {
                    return {
                        price: price.price * amount,
                        users: price.allowed_connections,
                        validTime: price.valid_time
                    };
                }
            }

        }
        return { price: 0, users: 0, validTime: 0 };
    }

    const updateAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch({
            type: cartActions.UPDATE_TICKET_AMOUNT,
            value: {
                amount: e.target.value,
                type: props.type,
                id: props.id,
                pricingId: props.pricingId
            }
        })
    }

    const removeTicketFromCart = () => {
        dispatch({
            type: cartActions.REMOVE_TICKET, value: {
                id: props.id,
                pricingId: props.pricingId,
                type: props.type
            }
        })
    }

    let ticketInfo = findTicketInfo();

    return (
        <div className="Ticket">
            <div className="ticket-info">
                <p>Sallitut katsojat: {ticketInfo.users}</p>
                <p>Lipun käyttöaika: {formatTimeWithDays(ticketInfo.validTime)}</p>
            </div>
            <div className="ticket-pricing">
                <Input
                    onChange={updateAmount}
                    minValue={0}
                    defaultValue={amount}
                    formRef={props.formRef}
                    type="number"
                    title="Kpl" name={"amount_" + props.type + "_" + props.id + "_price_" + props.pricingId} />
                <p>{ticketInfo.price.toFixed(2)}€</p>
            </div>
            <Button onClick={removeTicketFromCart} type="button-regular" color="main-color">Poista</Button>
        </div>
    );
}

const CartRow: React.FC<CartRowProps> = (props) => {
    const cartState = useContext(CartContext).state;
    const { state, dispatch } = useContext(VideosContext);
    const channelsContext = useContext(ChannelsContext);
    const storeContext = useContext(StoreContext);
    const [loading, isLoading] = useState(false);

    const getAllTicketsForCategory = () => {   
        return Object.keys(cartState.tickets[props.type][props.id]).map((key, index) => {
            return <Ticket
                key={key}
                formRef={props.formRef}
                pricingId={Number(key)}
                id={props.id}
                type={props.type} />
        });
    }
    const getVideoInfo = () => {
        if (props.type === 'channels') {
            for (let channel of channelsContext.state.channels) {
                if (channel.id === Number(props.id)) {
                    return <div className="CartRow">
                        <div className="video-info">
                            <h3>{channel.title}</h3>
                            <p>Kanavakohtainen lippu</p>
                        </div>
                        {getAllTicketsForCategory()}
                    </div>
                }
            }
            return null;
        }
        for (let channel in state) {
            if (typeof (state[channel]) === 'object') {
                //TODO this sucks, fix it?
                let type: 'liveEvents' | 'videos' = props.type !== 'videos' ? 'liveEvents' : 'videos';
                for (let video of state[channel][type] as TVideoData[] | TLiveData[]) {
                    if (video.id === Number(props.id)) {
                        return <div className="CartRow">
                            <div className="video-info">
                                <h3>{video.title}</h3>
                                <p>{video.channel_title}</p>
                            </div>
                            {getAllTicketsForCategory()}
                        </div>
                    }
                }
            }
        }
        return null;
    }

    const getChannelContents = (channelId: number, channels: Channels) => {
        channels.getVideosFromChannel(channelId).then(response => {
            if (response.status === 200) {
                dispatch(
                    {
                        type: videosActions.SET_ALL_VIDEOS,
                        value:
                        {
                            channelId: channelId,
                            videos: response.data
                        }
                    }
                );
            } else {
                console.log(response);
            }
        });
        channels.getLiveEventsFromChannel(channelId).then(response => {
            if (response.status === 200) {
                dispatch(
                    {
                        type: videosActions.SET_ALL_LIVE_EVENTS,
                        value:
                        {
                            channelId: channelId,
                            liveEvents: response.data
                        }
                    }
                );
            } else {
                console.log(response);
            }
        });

    }

    const getPrices = async () => {
        const prices = new Prices(apiRoutes.prices);
        const response = await prices.getAllPrices();
        if (response.status === 200) {
            storeContext.dispatch({ type: storeActions.SET_ALL_PRICES, value: response.data });
        } else {
            console.log(response);
        }
    }

    const getChannels = async () => {
        isLoading(true);
        let channels = new Channels(apiRoutes.channels);
        const response = await channels.getChannels();
        if (response.status === 200) {
            channelsContext.dispatch({ type: channelsActions.SET_ALL_CHANNELS, value: response.data });
        } else {
            console.log(response);
        }
        for (let channel of channelsContext.state.channels) {
            getChannelContents(channel.id, channels);
        }
        if (!storeContext.state.searchedAllPrices) {
            getPrices();
        }
        isLoading(false);
    }

    useEffect(() => {
        if (!channelsContext.state.searchedAllChannels) {
            getChannels();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return loading ? <LoadingIndicator /> : getVideoInfo();
}
const CartContainer: React.FC = () => {
    const [terms, setTerms] = useState<JSX.Element | null>(null);
    const { state, dispatch } = useContext(CartContext);
    const storeState = useContext(StoreContext).state;
    const formRef = createRef<HTMLFormElement>();
    const history = useHistory();
    const appDispatch = useContext(AppContext).dispatch;
    const [checkingEmail, isCheckingEmail] = useState(false);

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    }

    const getTotalAmount = () => {

        const findPrice = (amount: number, pricingId: number) => {
            for (let prices in storeState.prices) {
                for (let price of storeState.prices[prices]) {
                    if (price.pricing_id === pricingId) {
                        return price.price * amount;
                    }
                }

            }
            return 0;
        }
        let totalAmount = 0;
        for (let type in state.tickets) {
            for (let id in state.tickets[type as TTicketType]) {
                for (let pricingId in state.tickets[type as TTicketType][id]) {
                    totalAmount += findPrice(state.tickets[type as TTicketType][id][pricingId], Number(pricingId));

                }
            }

        }
        return totalAmount.toFixed(2) + '€';
    }
    const proceedToPay = () => {
        let formData = Object.fromEntries(new FormData(formRef.current as HTMLFormElement));
        dispatch({type: cartActions.SET_CUSTOMER_EMAIL, value: formData.email});
        payments.postPaymentRequest(formData).then(response => {
            if(response.status === 200){
                dispatch({ type: cartActions.SET_PAYMENT_INFO, value: response.data });
                history.push(routes.paymentsPage.route);
            } else {
                appDispatch({type: appActions.SET_ALERT_MESSAGE, value: response.data})
                isCheckingEmail(false);           
            }
        }).catch(err => console.log(err));
        isCheckingEmail(true);           
    }

    const showSaleTerms = (hide?: boolean) => {
        if (terms || hide) {
            setTerms(null);
        } else {
            setTerms(
                <Modal>
                    <TermsOfSale close={() => showSaleTerms(true)} />
                </Modal>
            )
        }
    }

    useEffect(() => {
        if (state.paymentInfo) {
            history.push(routes.paymentsPage.route);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={checkingEmail ? 'CartContainer requesting' : 'CartContainer'}>
            {checkingEmail ? <><h1>Tarkistetaan sähköpostiosoitetta</h1><LoadingIndicator /></> : null}
            {terms}
            <h1 className="cart-title">Ostoskori</h1>
            <Form ref={formRef} className="Cart" method="POST" action="" onSubmit={onSubmit}>
                {Object.keys(state.tickets.videos).length > 0 ? Object.keys(state.tickets.videos).map((key, index) => {
                    return <CartRow type={'videos'} id={key} key={index} formRef={formRef} />
                }) : null}
                {Object.keys(state.tickets.live).length > 0 ? Object.keys(state.tickets.live).map((key, index) => {
                    return <CartRow type={'live'} id={key} key={index} formRef={formRef} />
                }) : null}
                {Object.keys(state.tickets.channels).length > 0 ? Object.keys(state.tickets.channels).map((key, index) => {
                    return <CartRow type={'channels'} id={key} key={index} formRef={formRef} />
                }) : null}
                {Object.keys(state.tickets.videos).length > 0 || Object.keys(state.tickets.live).length > 0 || Object.keys(state.tickets.channels).length > 0 ?
                    <>
                        <h3 className="total-amount">Yhteensä (sis. ALV 24%): {getTotalAmount()}</h3>
                        <Input className="customer-email" defaultValue={state.customerEmail} required={true} type="email" name="email" title="Sähköpostiosoitteesi" formRef={formRef} />
                        <div className="terms">
                            <div>
                                <CheckBox ariaLabel="Olen lukenut ja hyväksyn myynnin ehdot" title="" name={"termsofsale"} formRef={formRef} required={true} />
                                <Link to="#" color="link-dark" onClick={showSaleTerms}>Olen lukenut ja hyväksyn<br />myynnin ehdot</Link>
                            </div>
                        </div>
                        <Button type="submit-regular" color="main-color" onClick={proceedToPay}>Siirry maksamaan</Button>
                    </>

                    :
                    <h3 className="total-amount" style={{ textAlign: 'center' }}>Ostoskori on tyhjä</h3>}
            </Form>
        </div>
    );
}

export default CartContainer;
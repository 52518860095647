import React from 'react';
import { IFormElement } from '..';
import './Input.scss';

interface InputProps extends IFormElement{
    type: "text" | "email" | "password" | "number";
    minLength?: number;
    maxLength?: number;
    minValue?: number;
    defaultValue?: string | number;
    onChange?: (e:React.ChangeEvent<HTMLInputElement>) => void;
    ariaLabel?: string
}

const Input: React.FC<InputProps> = (props) => {
    
    return(
        <>
        <label className={props.className ? 'Label ' + props.className : 'Label'} htmlFor={props.name}>{props.title}</label>
        <input aria-label={props.ariaLabel} id={props.name} tabIndex={props.tabIndex} min={props.minValue} onChange={props.onChange} defaultValue={props.defaultValue} type={props.type} className={props.className ? 'Input ' + props.className : 'Input'} name={props.name} required={props.required} maxLength={props.maxLength} minLength={props.minLength} />
        </>
    );
}

export default Input;
import React from 'react';
import { IFormElement } from '..';
import './CheckBox.scss';

interface CheckBoxProps extends IFormElement{
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}
const CheckBox: React.FC<CheckBoxProps> = (props) => {
    return(
        <input aria-label={props.ariaLabel} tabIndex={props.tabIndex} name={props.name} onChange={props.onChange} className="CheckBox" type="checkbox" required={props.required}/>
    );
}

export default CheckBox;
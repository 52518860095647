import React from 'react';
import { SVGProps, SVGBase } from './SVGTypes';

const InfoIcon: React.FC<SVGProps> = (props) => {
    return (
        <SVGBase 
            width={40} 
            height={40} 
            scale={props.scale}
            ariaLabel=""
            className="InfoIcon">
            <path fill="none" d="M20,38.5A18.5,18.5,0,1,1,38.5,20,18.52,18.52,0,0,1,20,38.5Z" />
            <path className="fill-color" d="M20,3A17,17,0,1,1,3,20,17,17,0,0,1,20,3m0-3A20,20,0,1,0,40,20,20,20,0,0,0,20,0Z" />
            <path className="fill-color" d="M21.5,15.8h.6V26.69a2.09,2.09,0,0,0,2.13,2.24v.6h-8.3v-.6a3.19,3.19,0,0,0,1.77-.75,2.71,2.71,0,0,0,.44-1.76v-7a2.43,2.43,0,0,0-.46-1.69,3.75,3.75,0,0,0-1.91-.75v-.6ZM17.83,9.89a2.17,2.17,0,0,1,.68-1.61,2.24,2.24,0,0,1,1.63-.67,2.21,2.21,0,0,1,1.61.66,2.18,2.18,0,0,1,.67,1.61,2.24,2.24,0,0,1-.67,1.63,2.18,2.18,0,0,1-1.61.67,2.21,2.21,0,0,1-1.63-.68A2.23,2.23,0,0,1,17.83,9.89Z" />
        </SVGBase>
    );
}

export default InfoIcon;
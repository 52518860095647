import { getCookieValue, saveCookie, setToSessionStorage, deleteCookie } from 'constants/functions';
import React, { createContext } from 'react';
import { cartActions } from './actions';

const cartCookieName = "VIDEOKANAVA_CART";
const paymentCookieName = "VIDEOKANAVA_ACTIVE_PAYMENT";
const customerEmailCookieName = "VIDEOKANAVA_CUSTOMER_EMAIL";

type TTicketType = "videos" | "live" | "channels";
type TAction = {
    type: string;
    value?: any;
}

type TTickets = {
    [videoOrChannelId: string]: {
        [pricingId: string]: number;
    };
};

type TProvider = {
    group: string;
    icon: string;
    id: string;
    name: string;
    svg: string;
    url: string;
    parameters: { name: string, value: string }[]
}

type TPaymentInfo = {
    transactionId: string;
    terms: string;
    reference: string;
    providers: TProvider[];
    href: string;
    groups: { icon: string, id: string, name: string, svg: string }[];
    customProviders: {
        applePay: { name: string, value: string }[]
    }
}

type TCartState = {
    // [key: string]: any;
    tickets: {
        videos: TTickets;
        live: TTickets;
        channels: TTickets;
    };
    paymentInfo: TPaymentInfo | undefined;
    customerEmail: string | undefined;
}

const getTicketsFromCookie = (): {
    videos: TTickets;
    live: TTickets;
    channels: TTickets;
} => {
    let cart = getCookieValue(cartCookieName);
    if (cart !== "") {
        return JSON.parse(cart);
    }
    return {
        videos: {},
        live: {},
        channels: {}
    }
}

const getPaymentInfoFromSessionStorage = (): TPaymentInfo | undefined => {
    let paymentInfo = window.sessionStorage.getItem(paymentCookieName);
    if (typeof (paymentInfo) === 'string' && paymentInfo !== 'undefined') {
        return JSON.parse(paymentInfo);
    }
    return undefined;
}

const getCustomerEmailFromSessionStorage = (): string | undefined => {
    let customerEmail = window.sessionStorage.getItem(customerEmailCookieName);
    if (typeof (customerEmail) === 'string' && customerEmail !== 'undefined') {
        return customerEmail;
    }
    return undefined;
}

const initialCartState: TCartState = {
    tickets: getTicketsFromCookie(),
    paymentInfo: getPaymentInfoFromSessionStorage(),
    customerEmail: getCustomerEmailFromSessionStorage()
}

// this would be better, it just does not work somehow
// const addTicketsToCart = (tickets: TTickets, pricings: { [pricingId: string]: number }, id: string): TTickets => {
//     if (!tickets[id]) {
//         tickets[id] = {
//             pricingIds: {}
//         };
//     }
//     for (let pricingId in pricings) {
//         if (!tickets[id].pricingIds[pricingId]) {
//             tickets[id].pricingIds[pricingId] = Number(pricings[pricingId]);
//         } else if (tickets[id].pricingIds[pricingId] > 0) {
//             tickets[id].pricingIds[pricingId] = tickets[id].pricingIds[pricingId] + Number(pricings[pricingId]);
//         }
//     }

//     return tickets;
// }

// const iterateTickets = (tickets: TTickets, value: TTickets): TTickets => {
//     for (let id in value) {
//         tickets = addTicketsToCart(tickets, value[id].pricingIds, id);
//     }
//     return tickets;
// }

function cartReducer(state: TCartState, action: TAction): TCartState {
    switch (action.type) {
        case cartActions.SET_CUSTOMER_EMAIL:
            state.customerEmail = action.value;
            setToSessionStorage(customerEmailCookieName, action.value);
            break;
        case cartActions.SET_PAYMENT_INFO:
            state.paymentInfo = action.value;
            setToSessionStorage(paymentCookieName, JSON.stringify(action.value));
            break;
        case cartActions.ADD_TO_CART:
            if (typeof (action.value) === 'object') {
                // iteration broke this somehow, caused the value to be added twice
                // state.tickets = iterateTickets(state.tickets, action.value);
                state.tickets = action.value;
            }
            saveCookie(cartCookieName, state.tickets, 24 * 60 * 60 * 1000);
            break;
        case cartActions.UPDATE_TICKET_AMOUNT:
            try {
                let id = action.value.id;
                let type = action.value.type as "videos" | "live" | "channels";
                if (action.value.amount > 0) {
                    state.tickets[type][id][action.value.pricingId] = action.value.amount;
                } else {
                    delete state.tickets[type][id][action.value.pricingId];
                    if (Object.keys(state.tickets[type][id]).length === 0) {
                        delete state.tickets[type][id];
                    }
                }
                // need to spread to update the state at this point
                state = { ...state };
            } catch (error) {
                // instead of extensive null checking
                console.log(error);

            }
            saveCookie(cartCookieName, state.tickets, 24 * 60 * 60 * 1000);
            break;
        case cartActions.REMOVE_TICKET:
            try {
                let id = action.value.id;
                let type = action.value.type as "videos" | "live" | "channels";
                delete state.tickets[type][id][action.value.pricingId];
                if (Object.keys(state.tickets[type][id]).length === 0) {
                    delete state.tickets[type][id];
                }
                // need to spread to update the state at this point
                state = { ...state };
            } catch (error) {
                // instead of extensive null checking
                console.log(error);

            }
            saveCookie(cartCookieName, state.tickets, 24 * 60 * 60 * 1000);

            break;
        case cartActions.EMPTY_CART:
            state.tickets = {
                videos: {},
                live: {},
                channels: {}
            }
            deleteCookie(cartCookieName);
            break;
        default:
            break;
    }
    return { ...state };
}

const CartContext = createContext<{ state: TCartState, dispatch: React.Dispatch<TAction> }>({ state: initialCartState, dispatch: () => { } });

export {
    cartReducer,
    CartContext,
    initialCartState
}
export type { TTickets, TProvider, TTicketType }
import React, { createContext } from 'react';
import { MOTDActions } from './actions';

type TAction = {
    type: string;
    value: any;
}

type TMessage = {
    title: string;
    description: string;
    image_id: number;
    link?: string;
}

type TMOTDState = {
    messages: TMessage[],
    searchedNextLiveEvent: boolean
};

const initialMOTDState: TMOTDState = {
    messages:[],
    searchedNextLiveEvent: false
}

function MOTDReducer(state: TMOTDState, action: TAction): TMOTDState {
    switch (action.type) {
        case MOTDActions.SET_ALL_MOTDS:
            state.messages = action.value;
            break;
        case MOTDActions.ADD_MOTD:
            state.messages.push(action.value);
            break;
        case MOTDActions.SEARCHED_NEXT_LIVE_EVENT:
            state.searchedNextLiveEvent = true;
            break;
        default:
            break;
    }
    return { ...state };
}

const MOTDContext = createContext<{ state: TMOTDState, dispatch: React.Dispatch<TAction> }>({ state: initialMOTDState, dispatch: () => { } });

export { initialMOTDState, MOTDContext, MOTDReducer};
export type {TMessage}
import Page from 'components/Page';
import VideoContainer from 'components/VideoContainer';
import React from 'react';
import './WatchLivePage.scss';

const WatchLivePage: React.FC = () => {
    
    return(
        <Page className="WatchLivePage">
            <VideoContainer type="live" />
        </Page>
    );
}

export default WatchLivePage;
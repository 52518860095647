import { clearFocus } from 'constants/functions';
import React from 'react';
import { IFormElement } from '..';
import './Select.scss';

interface SelectProps extends IFormElement {
    className?: string;
    onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

const Select: React.FC<SelectProps> = (props) => {

    return(
        <>
            <label htmlFor={props.name}>{props.title}</label>
            <select 
                onChange={(e) => {
                    clearFocus(0);
                    if(props.onChange) props.onChange(e);
                }} 
                id={props.name}
                required={props.required} 
                className={props.className ? "Select " + props.className :  "Select"} 
                name={props.name}
                defaultValue="empty">
                <option style={{display: "none"}} value="empty" disabled></option>
                {props.children}
            </select>
        </>
    );
}

export default Select;
import React, { useContext, useEffect, useState } from 'react';
import './SearchPage.scss';

import { useParams } from 'react-router-dom';
import Page from 'components/Page';
import { VideosContext } from 'contexts/VideosContext/reducer';
import { apiRoutes } from 'constants/constants';
import { formatTime, matchStringToObjArrValues } from 'constants/functions';
import { Channels } from 'constants/classes/Channels';
import { ChannelsContext } from 'contexts/ChannelsContext/reducer';
import { videosActions } from 'contexts/VideosContext/actions';
import { channelsActions } from 'contexts/ChannelsContext/actions';
import VideoCard from 'components/VideoCard';
import Button from 'components/Button';
import LoadingIndicator from 'components/LoadingIndicator';

const SearchPage: React.FC = () => {
    const { input } = useParams() as { input: string };
    const [loading, isLoading] = useState(true);
    const { state } = useContext(VideosContext);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchResults, setSearchResults] = useState<JSX.Element[]>([]);
    const channels = useContext(ChannelsContext);
    const videos = useContext(VideosContext);

    const getCurrentPage = () => {
        return searchResults.slice(currentPage * 6, currentPage * 6 + 6 > searchResults.length ? searchResults.length : currentPage * 6 + 6);
    }

    const getAmountOfPages = () => {
        return Math.ceil(searchResults.length / 6);
    }

    const getPageSwitchingButtons = () => {
        let buttons: any = [];
        for (let i = 1; i < getAmountOfPages() + 1; i++) {
            buttons.push(
                <Button
                    key={i}
                    type="button-regular"
                    color={currentPage === i - 1 ? "main-color" : "tertiary-color"}
                    onClick={() => {
                        setCurrentPage(i - 1);
                    }}>{i}</Button>
            );
        }

        return buttons;
    }
    // TODO redundancy here, check header methods
    const getChannelContent = (ch: Channels, id: number) => {
        ch.getLiveEventsFromChannel(id).then(response => {
            videos.dispatch(
                {
                    type: videosActions.SET_ALL_LIVE_EVENTS,
                    value: {
                        channelId: id,
                        liveEvents: response.data
                    }
                }
            );
        });
        ch.getVideosFromChannel(id).then(response => {
            videos.dispatch(
                {
                    type: videosActions.SET_ALL_VIDEOS,
                    value: {
                        channelId: id,
                        videos: response.data
                    }
                }
            );
        });

    }

    const getAllContent = (): void => {
        const ch = new Channels(apiRoutes.channels);
        ch.getChannels().then(async (response) => {
            if (!response.data) return;
            await channels.dispatch({ type: channelsActions.SET_ALL_CHANNELS, value: response.data });
            for (let channel of response.data) {
                getChannelContent(ch, channel.id);
            }
        });
    }
    const getSearchResults = () => {
        isLoading(true);
        let results: { videos: any[], liveEvents: any[] } = { videos: [], liveEvents: [] };
        for (let key in state) {
            if (typeof (state[key]) === 'object' && state[key].videos && state[key].liveEvents) {
                // @ts-ignore
                results.videos = [...results.videos, ...[...state[key].videos]];
                // @ts-ignore
                results.liveEvents = [...results.liveEvents, ...[...state[key].liveEvents]];
            }
        }

        let videoMatches = matchStringToObjArrValues(results.videos, input, true).map((data, index) => ({
            content: <VideoCard
                key={`live-${index}`}
                data={data.value}
                time={formatTime(data.value?.length)}
                type="videos" />,
            match: data.match
        }));
        let liveMatches = matchStringToObjArrValues(results.liveEvents, input, true).map((data, index) => ({
            content: <VideoCard
                key={`video-${index}`}
                data={data.value}
                time={new Date((data.value?.start_date as number) * 1000).toLocaleString()}
                type="live" />,
            match: data.match
        }));
        isLoading(false);
        setSearchResults([...videoMatches, ...liveMatches].sort((a, b) => (a.match > b.match) ? 1 : -1).map((data) => data.content))
    }

    useEffect(() => {
        if (!channels.state.searchedAllChannels) {
            getAllContent();
        }
        getSearchResults();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state]);
    return (
        <Page className="SearchPage">
            <div className="content">
                <h2>Hakutulokset hakusanalla: {input}</h2>
                {loading ? <LoadingIndicator /> : getCurrentPage()}
                <div className="page-switching-buttons">
                    {getPageSwitchingButtons()}
                </div>
            </div>
        </Page>
    );
}

export default SearchPage;
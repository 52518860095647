import Page from 'components/Page';
import { Select, Form, TextArea, Input } from 'components/FormElements';
import React, { createRef, useContext, useState } from 'react';
import './ContactPage.scss';
import Button from 'components/Button';
import { Contact } from 'constants/classes/Contact';
import { apiRoutes, routes } from 'constants/constants';
import { clearFocus } from 'constants/functions';
import { AppContext } from 'contexts/AppContext/reducer';
import { appActions } from 'contexts/AppContext/actions';
import Link from 'components/Link';
import PageDescription from 'components/PageDescription';

const FAQ: React.FC<{ title: string }> = (props) => {
    const [active, setActive] = useState(false);

    const dropDownClicked = () => {
        if (active) {
            clearFocus(0);
        }
        setActive(!active);
    }
    const onBlur = (e: React.FocusEvent<HTMLDivElement>) => {
        if (e.relatedTarget === null) {
            setActive(false);
        }
    }
    return (
        <div className="FAQ" onBlur={onBlur}>
            <Button isTitle="title-3" onClick={dropDownClicked} className="dropdown-toggle" type='button-regular' color="tertiary-color">
                {props.title}
            </Button>
            <div tabIndex={-1} className="faq-container">
                {props.children}
            </div>
        </div>
    );
}
const FAQS: React.FC = () => {
    return (
        <>
            <FAQ title="Haluan ostaa käyttöoikeuksia videomateriaaleihin">
                <p>Kulttuuriosuuskunta TERÄllä on täydet jälleenmyyntioikeudet osaan tällä sivustolla tarjottuihin videomateriaaleihin, ja niihin voi ostaa käyttöoikeuksia asiakkaan omaan tuotantoon.</p>
                <p>Materiaaleista joilla TERÄllä ei ole jälleenmyyntioikeuksia, voidaan neuvotella materiaalin haltijan kanssa, mutta näiden materiaalien saatavuus ei ole taattu.</p>
                <p>Videomateriaalin toimitus tapahtuu asiakkaan kanssa sovitulla tavalla, joko fyysisesti tai sähköisesti.</p>
            </FAQ>
            <FAQ title="Haluan tehdä tilauksen tapahtumalleni">
                <p>Tämän sivuston palvelut tuotetaan Kulttuuriosuuskunta TERÄn toimesta, ja voit tilata eri tasoisia palveluita ottamalla yhteyttä joko tämän lomakkeen avulla, lähettämällä meille suoraan sähköpostia, tai soittamalla sivuston alalaidassa annettuun puhelinnumeroon.</p>
                <p>Kulttuuriosuuskunta TERÄ tarjoaa eri tasoisia palveluita, voimme tarjota tapahtumallesi yksilöllistetyn palvelun, johon voi sisältyä tuotanto, lipunmyynti ja materiaalin tarjoaminen tällä sivustolla kokonaisuudessaan, tai osa näistä kokonaisuuksista.</p>
                <p>Palvelujen hinta määräytyy tuotannon mukaan.</p>
            </FAQ>
            <FAQ title="Haluan perua ostotapahtumani">
                <p>Asiakkaalla on oikeus peruuttaa ostotapahtuma 14 päivän kuluessa syytä ilmoittamatta, tapauksessa jossa lippukoodia ei ole vielä käytetty.</p>
                <p>Tapauksessa, jossa lippukoodi on käytetty, mutta asiakkaalla on esittää todisteet tuotteen puutteellisuudesta (esimerkiksi lippukoodin toimimattomuudesta <Link to="/accessibility" color="link-dark">saavutettavuusselosteessa</Link> annetuista toiminnan takeista huolimatta), tuotteen hinta voidaan korvata kokonaisuudessaan tapauskohtaisesti.</p>
                <p>Peruuttamisen määräaika päättyy 14 päivän kuluttua ostotapahtumasta.</p>
                <p>Peruuttamisoikeuden käyttämiseksi teidän on ilmoitettava meille päätöksestänne peruuttaa sopimus yksiselitteisellä tavalla (esimerkiksi kirjeellä postitse tai sähköpostilla). Voitte käyttää liitteenä olevaa peruuttamislomaketta, mutta sen käyttö ei ole pakollista.</p>
                <p>Peruuttamisen määräajan noudattamiseksi riittää, että lähetätte ilmoituksenne peruuttamisoikeuden käytöstä ennen peruuttamisajan päättymistä.</p>
                <p>Jos peruutatte ostotapahtuman, palautamme teille kaikki teiltä saamamme suoritukset viivytyksettä ja joka tapauksessa viimeistään 14 päivän kuluttua peruuttamisilmoituksen saatuamme. Suoritamme palautuksen sillä maksutavalla, jota olette käyttänyt alkuperäisessä liiketoimessa, ellette ole nimenomaisesti suostunut muuhun, ja joka tapauksessa siten, että teille ei aiheudu suoritusten palauttamisesta kustannuksia.</p>
                <p>Ostotapahtuman peruuttamista varten tietoihin tulee lisätä asiakkaan sähköposti, johon lippu on toimitettu, sähköpostiin toimitettu tilausnumero sekä mahdolliset asiakkaan yhteystiedot. Tapauksessa jossa koodia on käytetty, tulee myös sisällyttää perusteet ostotapahtuman perumiselle.</p>
                <p>Tilanteessa jossa koodia on käytetty, tai jokin muu ostotapahtuman peruuttamisen oikeutuksista ei täyty, otamme yhteyttä joko sähköpostilla, tai asiakkaan yhteydenottopyynnössä esittämällä tavalla mahdollisimman pian, kuitenkin viimeistään 14 päivän kuluttua yhteydenottopyynnön lähettämisestä.</p>
            </FAQ>
            <FAQ title="Lippua ei toimitettu sähköpostiini">
                <p>Jos tilausvahvistusta ei saapunut, tarkistathan roskapostikansion.</p>
                <p>Joissakin tapauksissa sähköpostipalvelun tarjoaja on voinut estää viestien vastaanottamisen osoitteesta videokanava.fi, jos epäilet että tämä pitää paikkansa, otathan yhteyttä tällä lomakkeella.</p>
                <p>Jos lomakkeen lähetys ei onnistu, syötäthän toisen sähköpostiosoitteen, voit ottaa yhteyttä suoraan osoitteeseen <a href="mailto: tuki@videokanava.fi">tuki@videokanava.fi</a> tai soittamalla numeroon <a href="tel:+358-400-485-500">+358 400 485 500</a>.</p>
            </FAQ>
            <FAQ title="Lippuni ei toimi">
                <p><Link to="/accessibility" color="link-dark">Saavutettavuusselosteessa</Link> on selvitetty lippujen käyttöön liittyvät järjestelmävaatimukset, lippujen toiminnasta ei ole takeita näiden rajausten ulkopuolella.</p>
                <p>Huomioithan lippua syöttäessäsi, että lippukoodissa on kuusi (6) merkkiä, ja ainoastaan pieniä kirjaimia ja numeroita.</p>
                <p>Lippujen käyttöön liittyy rajoite samanaikaisten katsojien määrästä, joka on määritelty lipun tiedoissa ostotapahtuman yhteydessä.</p>
                <p>Jos lippu ei jostain muusta tuntemattomasta syystä toimi, otathan yhteyttä tämän lomakkeen avulla. Tapauksessa, jossa lippukoodin toiminnan puutteille löytyy tekninen vika sivustolla, voidaan asiakkaalle tarjota hyvitys lipun hinnasta, tai mahdollisesti ylimääräisiä katseluoikeuksia sivuston materiaaleihin.</p>
            </FAQ>
            <FAQ title="Saavutettavuusseloste on puutteellinen">
                <p>Jos saavutettavuusselosteessa on puutteita, tai sivuston saavutettavuus ei vastaa kriteereitä, voit antaa palautteen tällä lomakkeella.</p>
            </FAQ>
        </>
    );
}

const ContactPage: React.FC = () => {
    const formRef = createRef<HTMLFormElement>();
    const { dispatch } = useContext(AppContext);

    const submitContactForm = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        let formData = Object.fromEntries(new FormData(e.target as HTMLFormElement));
        if (!formData.title || formData.title === "empty") {
            dispatch({ type: appActions.SET_ALERT_MESSAGE, value: "Valitse yhteydenoton syy" });
            return;

        }
        let contact = new Contact(apiRoutes.contact);
        contact.postContactForm(formData as { title: string, description: string, email: string }).then(result => {
            dispatch({ type: appActions.SET_ALERT_MESSAGE, value: result.message })
        }
        );
    }
    return (
        <Page className="ContactPage">
            <PageDescription title={routes.contactPage.name}>
                <p>Tältä sivulta löydät lomakkeen yhteydenottoja varten, sekä neuvoja yleisimpiin ongelmiin.</p>
            </PageDescription>
            <div className="container">
                <FAQS />
                <h2>Yhteydenottolomake</h2>
                <Form onSubmit={submitContactForm} method="POST" action="" ref={formRef}>
                    <Select required={true} formRef={formRef} name="title" title="Valitse yhteydenoton syy">
                        <option>Haluan ostaa käyttöoikeuksia videomateriaaleihin</option>
                        <option>Haluan tehdä tilauksen tapahtumalleni</option>
                        <option>Haluan perua ostotapahtumani</option>
                        <option>Lippua ei toimitettu sähköpostiini</option>
                        <option>Lippuni ei toimi</option>
                        <option>Saavutettavuusseloste on puutteellinen</option>
                        <option>Muu syy</option>
                    </Select>
                    <TextArea required={true} formRef={formRef} name="description" title="Kuvaus" />
                    <Input required={true} formRef={formRef} name="email" type="email" title="Sähköpostiosoitteesi" />
                    <Button type="submit-regular" color="main-color">Lähetä</Button>
                </Form>
            </div>
        </Page>
    );
}

export default ContactPage;
import React from 'react';
import './PageDescription.scss';

type PageDescriptionProps = {
    title: string;
}
const PageDescription: React.FC<PageDescriptionProps> = (props) => {
    return(
        <div className='PageDescription'>
            <h1>{props.title}</h1>
            {props.children}
        </div>
    );
}

export default PageDescription;
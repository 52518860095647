import LoadingIndicator from 'components/LoadingIndicator';
import { Images } from 'constants/classes/Images';
import { apiRoutes } from 'constants/constants';
import { imagesActions } from 'contexts/ImagesContext/actions';
import { ImagesContext } from 'contexts/ImagesContext/reducer';
import React, { useContext, useState, useEffect } from 'react';
import './Image.scss';

type ImageProps = {
    id: number;
}

const Image: React.FC<ImageProps> = (props) => {
    const { state, dispatch } = useContext(ImagesContext);
    const [loading, isLoading] = useState(true);
    
    const getImage = async() => {
        const images = new Images(apiRoutes.images);
        let imageData = await images.getImageWithId(props.id);

        if(imageData && imageData.data) {
            dispatch({type: imagesActions.SET_IMAGE, value: {[props.id]: imageData.data}});
        }
        isLoading(false);
    }

    useEffect(() => {
        if(!state[props.id]){
            getImage();
        } else {
            isLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    if(!state[props.id] && loading){
        return <LoadingIndicator />
    }
    
    return (
        <img className='Image' src={`data:${state[props.id].type};base64,${state[props.id].data}`} alt={state[props.id].alt} />
    ); 
}

export default Image;
import React from 'react';
import './LoadingIndicator.scss';

const LoadingIndicator: React.FC = () => {
    return(
        <div className='LoadingIndicator'>
            <span></span>
            <span></span>
            <span></span>
        </div>
    );
}

export default LoadingIndicator;
import {apiURL} from "constants/constants"
import axios from "axios";

interface IApiCalls{
    url: string;
    route: string;
}

export class ApiCalls implements IApiCalls{
    public url: string;
    public route: string;

    constructor(route: string){
        this.url = apiURL;
        this.route = route;
    }

    public async get(): Promise<{ [key: string]: any }>{
        let response: {[key: string]: any } | Error;
        try {
            response = await axios.get(`${this.url}${this.route}`).catch(err => {
                return {
                    status: err.response.status,
                    message: err.response.message
                };
            });
        } catch (error) {
            response = {
                status: 510,
                message: "Unknown error occurred"
            };
        }
        return response;
    }
}
import ChannelDropDown from 'components/ChannelDropDown';
import Page from 'components/Page';
import PageDescription from 'components/PageDescription';
import { Channels } from 'constants/classes/Channels';
import { apiRoutes, routes } from 'constants/constants';
import { channelsActions } from 'contexts/ChannelsContext/actions';
import { ChannelsContext } from 'contexts/ChannelsContext/reducer';
import React, { useContext, useEffect, useState } from 'react';
import './VideosPage.scss';


const VideosPage: React.FC = () => {
    const { state, dispatch } = useContext(ChannelsContext);
    const [dropDownClickedId, setDropDownClickedId] = useState<number | null>(null);

    const getChannels = async () => {
        const channels = new Channels(apiRoutes.channels);
        const response = await channels.getChannels();
        if (response.status === 200) {
            dispatch({ type: channelsActions.SET_ALL_CHANNELS, value: response.data });
        } else {
            console.log(response);
        }
    }

    useEffect(() => {
        if (!state.searchedAllChannels) {
            getChannels();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // TODO dont display channels with no active materials
    return (
        <Page className="VideosPage">
            <PageDescription title={routes.videosPage.name}>
            <p>Tällä sivulla on kaikki sivuston videotallenteet kanavakohtaisesti jaoteltuna.</p>
            </PageDescription>
            {state.channels.map((channel, index) =>
                <ChannelDropDown key={index} dropDownClickedId={dropDownClickedId} setDropDownClickedId={setDropDownClickedId} data={channel} type="videos" />
            )}
        </Page>
    );
}

export default VideosPage;
import React, { createContext } from 'react';
import { storeActions } from './actions';

type TAction = {
    type: string;
    value: any;
}
type TStoreState = {
    searchedAllPrices: boolean;
    prices: {
        [key: string]: {
            pricing_id: number;
            price: number;
            valid_time: number;
            allowed_connections: number;
        }[]
    }
}

const initialStoreState: TStoreState = {
    searchedAllPrices: false,
    prices: {}
}

function storeReducer(state: TStoreState, action: TAction): TStoreState {
    switch (action.type) {
        case storeActions.SET_ALL_PRICES:
            if (action.value) {
                state.prices = action.value;
                state.searchedAllPrices = true;
            }
            break;
        case storeActions.ADD_PRICING:
            if (action.value) {
                state.prices = {...state.prices, ...action.value };
            }
            break;
        default:
            break;
    }
    return { ...state };
}

const StoreContext = createContext<{ state: TStoreState, dispatch: React.Dispatch<TAction> }>({ state: initialStoreState, dispatch: () => { } });

export { initialStoreState, StoreContext, storeReducer };
import { ApiCalls } from "constants/classes/ApiCalls";
import axios, { AxiosResponse } from "axios";

export class Prices extends ApiCalls {
    public async getAllPrices(): Promise<{[key: string]: any }> {
        let response: AxiosResponse | {status: number, message: string};
        try {
            response = await axios.get(`${this.url}${this.route}`).catch(err => {
                return {
                    status: err.response.status,
                    message: err.response.message
                };
            });
        } catch (error) {
            response = {
                status: 510,
                message: "Unknown error occurred"
            };
        }
        return response;
    }
    public async getPricesWithId(pricingId: number): Promise<{[key: string]: any }> {
        let response: AxiosResponse | {status: number, message: string};
        try {
            response = await axios.get(`${this.url}${this.route}/${pricingId}`).catch(err => {
                return {
                    status: err.response.status,
                    message: err.response.message
                };
            });
        } catch (error) {
            response = {
                status: 510,
                message: "Unknown error occurred"
            };
        }
        return response;
    }
}
import React from 'react';
import './Form.scss';

type FormProps = {
    className?: string;
    method: "GET" | "POST";
    action: string;
    onSubmit?: (e: React.FormEvent<HTMLFormElement>) => void;
}

const Form = React.forwardRef<HTMLFormElement, React.PropsWithChildren<FormProps>>((props, ref) => {
    return (
        <form ref={ref} className={props.className ? 'Form ' + props.className : 'Form'} onSubmit={props.onSubmit}>
            {props.children}
        </form>
    );
});

export default Form;
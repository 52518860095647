import React from 'react';
import {SVGBase, SVGProps} from './SVGTypes';

const CartIcon: React.FC<SVGProps> = (props) => {
    return (
        <SVGBase ariaLabel="" width={27.6} height={16} scale={props.scale}>
            <path d="M5.45,3,4.81,0H0V2H3.19L3.4,3l.43,2,.75,3.5.43,2L6.19,16H24.81L26,10.5l.43-2L27.17,5l.43-2Zm13.3,2-.22,3.5H12.47L12.25,5ZM5.88,5h4.37l.22,3.5H6.63Zm1.93,9-.75-3.5h3.53l.22,3.5Zm5,0-.22-3.5h5.8L18.18,14Zm10.37,0h-3l.22-3.5h3.53Zm1.18-5.5H20.54L20.75,5h4.37Z" />
        </SVGBase>
    );
}

export default CartIcon;
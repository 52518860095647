import React, { createContext } from 'react';
import { imagesActions } from './actions';

type TAction = {
    type: string;
    value: {
        [id: number]: {
            name: string,
            type: string,
            data: string,
            alt: string
        }
    };
}
type TImagesState = {
    [id: number]: {
        name: string,
        type: string,
        data: string,
        alt: string
    }
}

const initialImagesState: TImagesState = {

}

function imagesReducer(state: TImagesState, action: TAction): TImagesState {
    switch (action.type){
        case imagesActions.SET_ALL_IMAGES:
            state = action.value;
            break;
        case imagesActions.SET_IMAGE:
            state = {...state, ...action.value};
            break;
        default:
            break;
    }
    return {...state};
}

const ImagesContext = createContext<{state: TImagesState, dispatch: React.Dispatch<TAction>}>({state: initialImagesState, dispatch: () => {}});

export { initialImagesState, ImagesContext, imagesReducer };
import Button from 'components/Button';
import LoadingIndicator from 'components/LoadingIndicator';
import VideoCard from 'components/VideoCard';
import { Channels } from 'constants/classes/Channels';
import { apiRoutes } from 'constants/constants';
import { formatTime, sleep } from 'constants/functions';
import { TChannel } from 'contexts/ChannelsContext/reducer';
import { videosActions } from 'contexts/VideosContext/actions';
import { TLiveData, TVideoData, VideosContext } from 'contexts/VideosContext/reducer';
import React, { useContext, useState, useEffect, useRef } from 'react';
import './ChannelDropDown.scss';

type ChannelDropDownProps = {
    data: TChannel;
    type: "live" | "videos";
    dropDownClickedId: number | null;
    setDropDownClickedId: React.Dispatch<React.SetStateAction<number | null>>;
    nonDataClick?: {
        id: number;
        clicked: number | null;
    }; // this is to fix the spaghetti, so we can use this on channelspage too
}

const ChannelDropDown: React.FC<ChannelDropDownProps> = (props) => {
    const [loading, isLoading] = useState(false);
    const [mounted, isMounted] = useState(true);
    const { state, dispatch } = useContext(VideosContext);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const [currentPage, setCurrentPage] = useState(0);
    const [currentFocus, setCurrentFocus] = useState<any | null>(null);

    // TODO might lead to bug later on, since we set here the parameter for searched all videos
    const getVideos = async () => {
        let channels = new Channels(apiRoutes.channels);
        const response = await channels.getVideosFromChannel(props.data.id);
        if (response.status === 200) {
            dispatch(
                {
                    type: videosActions.SET_ALL_VIDEOS,
                    value:
                    {
                        channelId: props.data.id,
                        videos: response.data
                    }
                }
            );
            if (mounted) isLoading(false);
        } else {
            console.log(response);
        }
    }

    const getLiveEvents = async () => {
        let channels = new Channels(apiRoutes.channels);
        const response = await channels.getLiveEventsFromChannel(props.data.id);
        if (response.status === 200) {
            dispatch(
                {
                    type: videosActions.SET_ALL_LIVE_EVENTS,
                    value:
                    {
                        channelId: props.data.id,
                        liveEvents: response.data
                    }
                }
            );
            if (mounted) isLoading(false);
        } else {
            console.log(response);
        }
    }

    const dropDownClicked = (e: any) => {                
        if (!currentFocus || currentFocus === 'focus') {
            setCurrentFocus(e.type);
            if (props.nonDataClick) {
                if (props.nonDataClick.clicked === props.nonDataClick.id) {
                    props.setDropDownClickedId(null);
                } else {
                    props.setDropDownClickedId(props.nonDataClick.id);
                }
            } else {
                if (props.dropDownClickedId === props.data.id) {
                    props.setDropDownClickedId(null);
                } else {
                    props.setDropDownClickedId(props.data.id);
                }
            }
        } else if(currentFocus === 'click' && e.type === 'click'){
            setCurrentFocus(null);
            if (props.nonDataClick) {
                if (props.nonDataClick.clicked === props.nonDataClick.id) {
                    props.setDropDownClickedId(null);
                } else {
                    props.setDropDownClickedId(props.nonDataClick.id);
                }
            } else {
                if (props.dropDownClickedId === props.data.id) {
                    props.setDropDownClickedId(null);
                } else {
                    props.setDropDownClickedId(props.data.id);
                }
            }
        }
    }

    const onBlur = async (e: React.FocusEvent<HTMLDivElement>) => {
        setCurrentFocus(null);        
        if (e.relatedTarget === null) {
            await sleep(100);
            props.setDropDownClickedId(null);
        }
    }

    const getCurrentPage = (): JSX.Element[] => {
        let page: any = [];
        if (props.type === 'videos' && state[props.data.id] && state[props.data.id].videos) {
            // @ts-ignore
            page = state[props.data.id].videos.slice(currentPage * 6, currentPage * 6 + 6 > state[props.data.id].videos.length ? state[props.data.id].videos.length : currentPage * 6 + 6);
            // TODO fix missing materials
            page.length > 0 || loading ?
                page = page.map((video: TVideoData, index: number) =>
                    <VideoCard key={index} type={props.type} data={video} time={formatTime(video.length)} />
                )
                :
                page.push(
                    <h2 key={"no results"}>Ei tuloksia</h2>
                );
        }
        else if (props.type === 'live' && state[props.data.id] && state[props.data.id].liveEvents) {
            // @ts-ignore
            page = state[props.data.id].liveEvents.slice(currentPage * 6, currentPage * 6 + 6 > state[props.data.id].liveEvents.length ? state[props.data.id].liveEvents.length : currentPage * 6 + 6);
            page.length > 0 || loading ?
                page = page.map((liveEvent: TLiveData, index: number) =>
                    <VideoCard key={index} type={props.type} data={liveEvent} time={new Date((liveEvent.start_date as number) * 1000).toLocaleString()} />
                )
                :
                page.push(
                    <h2 key={"no results"}>Ei tuloksia</h2>
                );
        }
        return page;
    }

    const getPageSwitchingButtons = (): JSX.Element[] => {
        let buttons: any = [];
        for (let i = 1; i < getAmountOfPages() + 1; i++) {
            buttons.push(
                <Button
                    key={i}
                    type="button-regular"
                    color={currentPage === i - 1 ? "main-color" : "tertiary-color"}
                    onClick={() => {
                        setCurrentPage(i - 1);
                    }}>{i}</Button>
            );
        }

        return buttons;
    }

    const getAmountOfPages = (): number => {
        if (props.type === 'videos' && state[props.data.id] && state[props.data.id].videos) {
            // @ts-ignore
            return Math.ceil(state[props.data.id].videos.length / 6);
        }
        else if (props.type === 'live' && state[props.data.id] && state[props.data.id].liveEvents) {
            // @ts-ignore
            return Math.ceil(state[props.data.id].liveEvents.length / 6);
        }
        return 0;
    }

    const wasDropDownClicked = (): boolean => {
        return props.nonDataClick ? props.nonDataClick.id === props.nonDataClick.clicked : props.dropDownClickedId === props.data.id;
    }

    useEffect(() => {
        if (!state[props.data.id]) {
            isLoading(true);
            props.type === "videos" ? getVideos() : getLiveEvents();
        }
        else if (props.type === "live" && !state[props.data.id].searchedAllLiveEvents) {
            isLoading(true);
            getLiveEvents();
        }
        else if (props.type === "videos" && !state[props.data.id].searchedAllVideos) {
            isLoading(true);
            getVideos();
        }
        return () => {
            isMounted(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (state[props.data.id] && !loading) {
        if (props.type === 'live' && ((state[props.data.id].liveEvents && state[props.data.id].liveEvents?.length === 0) || !state[props.data.id].liveEvents)) {
            return null;
        } else if (props.type === 'videos' && ((state[props.data.id].videos && state[props.data.id].videos?.length === 0) || !state[props.data.id].videos)) {
            return null;
        }
    } else {
        return <LoadingIndicator />;
    }

    return (
        <div onBlur={onBlur} className={wasDropDownClicked() ? 'ChannelDropDown active' : 'ChannelDropDown'}>
            <Button isTitle="title-1" onFocus={dropDownClicked} onClick={dropDownClicked} className="dropdown-toggle" type='button-regular' color="tertiary-color">
                {props.data.title}
            </Button>
            <div ref={containerRef} className="videos-container" tabIndex={-1}>
                {getCurrentPage()}
                {getAmountOfPages() > 1 ?
                    <div className="videos-pagination">
                        {getPageSwitchingButtons()}
                    </div>
                    : null}
                {loading ? <LoadingIndicator /> : null}
            </div>
        </div>
    );
}

export default ChannelDropDown;
import Button from 'components/Button';
import Link from 'components/Link';
import { routes } from 'constants/constants';
import React from 'react';
import './TermsOfSale.scss';

type TermsOfSaleProps = {
    close?: () => void;
}
const TermsOfSale: React.FC<TermsOfSaleProps> = (props) => {
    return (
        <div className='TermsOfSale'>
            <section>
                <h2>Kanavat</h2>
                <p>Sivuston videomateriaalit on jaoteltu kanavakohtaisesti. Kanavalla tarkoitetaan kategoriaa, jonka alle video tai livelähetys on linkitetty.</p>
            </section>
            <section>
                <h2>Lipputyypit</h2>
                <p>Sivustolla on kahdenlaisia lippuja: videokohtaisia ja kanavakohtaisia. Videokohtaiset liput antavat katseluoikeuden yhteen videoon tai livelähetykseen. Kanavakohtaiset liput mahdollistavat pääsyn kaikkiin sen kanavan materiaaleihin, jolle lippu on ostettu.</p>
            </section>
            <section>
                <h2>Lippujen toiminta</h2>
                <p>Emme takaa lippujen toimivuutta kaikilla laitteilla. <Link color="link-dark" to={routes.accessibilityStatementPage.route}>Saavutettavuusselosteessa</Link> on lisätietoja sivuston toimivuuden takaamisesta.</p>
            </section>
            <section>
                <h2>Lipun voimassaoloaika</h2>
                <p>Lippukoodin voimassaoloaika on lipulle määritelty aika koodin ensimmäisestä käyttökerrasta.</p>
            </section>
            <section>
                <h2>Käyttöoikeus</h2>
                <p>Lippukoodit on tarkoitettu yksityiseen käyttöön, materiaalin esittäminen yleisötilaisuuksissa vaatii erillisen luvan materiaalin tarjoajalta.</p>
            </section>
            <section>
                <h2>Lippujen toimittaminen asiakkaalle</h2>
                <p>Lippukoodit toimitetaan asiakkaan ostotilanteessa tarjoamaan sähköpostiosoitteeseen heti kun maksu on vahvistettu.</p>
            </section>
            <section>
                <h2>Palautusoikeus</h2>
                <p>Lipun voi palauttaa ja kaupan purkaa tilanteessa, jossa ostotapahtumasta on kulunut korkeintaan 14 päivää, eikä lippua ole vielä käytetty.</p>
                <p>Jos lippu on käytetty, mutta asiakkaalla on esittää todisteet lipun toimimattomuudesta tai muista puutteista tuotteen käytössä, voidaan lipun hinta myös hyvittää kokonaisuudessaan.</p>
                <p>Kaupan voi purkaa lomakkeella, joka löytyy sivuston Ota yhteyttä -välilehdeltä, osoitteesta <Link tabIndex={2} color="link-dark" to="/contact">https://www.videokanava.fi/contact</Link>.</p>
            </section>
            <section>
                <h2>Maksupalvelutarjoaja</h2>
                <p>Maksunvälityspalvelun toteuttajana ja maksupalveluntarjoajana toimii Paytrail Oyj (2122839-7) yhteistyössä suomalaisten pankkien ja luottolaitosten kanssa. Paytrail Oyj näkyy maksun saajana tiliotteella tai korttilaskulla ja välittää maksun kauppiaalle. Paytrail Oyj:llä on maksulaitoksen toimilupa. Reklamaatiotapauksissa pyydämme ottamaan ensisijaisesti yhteyttä tuotteen toimittajaan.</p>
                <ul>
                    <li>Paytrail Oyj, y-tunnus: 2122839-7</li>
                    <li>Innova 2</li>
                    <li>Lutakonaukio 7</li>
                    <li>40100 Jyväskylä</li>
                    <li><Link to="https://www.paytrail.com/" target="_blank" color="link-dark">www.paytrail.com</Link></li>
                </ul>
            </section>
            {props.close !== undefined ? <Button tabIndex={2} color="main-color" type="button-regular" onClick={props.close}>Sulje</Button> : null}
        </div>
    );
}

export default TermsOfSale;
import Page from 'components/Page';
import PageDescription from 'components/PageDescription';
import TermsOfSale from 'components/TermsOfSale';
import { Prices } from 'constants/classes/Prices';
import { apiRoutes, routes } from 'constants/constants';
import { storeActions } from 'contexts/StoreContext/actions';
import { StoreContext } from 'contexts/StoreContext/reducer';
import React, { useEffect, useContext } from 'react';
import './StorePage.scss';


const StorePage: React.FC = () => {
    const { state, dispatch } = useContext(StoreContext);
    const prices = new Prices(apiRoutes.prices);

    const getAllPrices = async () => {
        let response = await prices.getAllPrices();
        if (response.status === 200) {
            dispatch(
                {
                    type: storeActions.SET_ALL_PRICES,
                    value: response.data
                });
        } else {
            console.log(response);
        }
    }

    useEffect(() => {
        if (!state.searchedAllPrices) {
            getAllPrices();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <Page className="StorePage">
            <PageDescription title={routes.storePage.name}>   
            <p>Lippujen myynti tapahtuu toistaiseksi suoraan videomateriaalien yhdeydestä, voit lisätä lippuja ostoskoriin materiaalin yhteydestä löytyvästä linkistä.</p>
            </PageDescription>
            <TermsOfSale />
        </Page>
    );
}

export default StorePage;
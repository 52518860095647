import ChannelDropDown from 'components/ChannelDropDown';
import Image from 'components/Image';
import LoadingIndicator from 'components/LoadingIndicator';
import Page from 'components/Page';
import { Channels } from 'constants/classes/Channels';
import { apiRoutes } from 'constants/constants';
import { channelsActions } from 'contexts/ChannelsContext/actions';
import { ChannelsContext, TChannel } from 'contexts/ChannelsContext/reducer';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './ChannelsPage.scss';

type ChannelProps = {
    data: TChannel
}

const ChannelCard: React.FC<ChannelProps> = (props) => {
    return (
        <div className="ChannelCard">
            <div className="channel-info">
                <h1>{props.data.title}</h1>
                {props.data.description.map(desc =>
                    desc.type === "paragraph" ? <p>{desc.text}</p> :
                        <h3>{desc.text}</h3>
                )}
            </div>
            <div className="image-container">
                <Image id={props.data.image_id} />
            </div>
        </div>
    );
}
const Channel: React.FC<ChannelProps> = (props) => {
    const [dropDownClickedId, setDropDownClickedId] = useState<number | null>(null);
    return (
        <div className="Channel">
            <ChannelCard data={props.data} />
            <ChannelDropDown 
                data={{...props.data, ...{title: "Videot"}}} 
                type="videos" 
                dropDownClickedId={dropDownClickedId} 
                setDropDownClickedId={setDropDownClickedId}
                nonDataClick={{
                    id: 1,
                    clicked: dropDownClickedId
                }} />
            <ChannelDropDown 
                data={{...props.data, ...{title: "Livelähetykset"}}} 
                type="live" 
                dropDownClickedId={dropDownClickedId} 
                setDropDownClickedId={setDropDownClickedId}
                nonDataClick={{
                    id: 2,
                    clicked: dropDownClickedId
                }} />
        </div>
    );
}
const ChannelsPage: React.FC = () => {
    const { channelId } = useParams() as { channelId: string };
    const { state, dispatch } = useContext(ChannelsContext);
    const [channelData, setChannelData] = useState<TChannel | undefined | string>(undefined);
    const [loading, isLoading] = useState(true);

    const getChannels = async () => {
        const channels = new Channels(apiRoutes.channels);
        const response = await channels.getChannels();
        if (response.status === 200) {
            dispatch({ type: channelsActions.SET_ALL_CHANNELS, value: response.data });
        } else {
            console.log(response);
        }

    }

    const getChannelData = async () => {
        let channel: TChannel | undefined = undefined;
        if (!state.searchedAllChannels) {
            await getChannels();
        }
        for (let ch of state.channels) {
            if (ch.id === Number(channelId)) {
                channel = ch;
                break;
            }
        }
        if (channel !== undefined) {
            setChannelData(channel);
        } else {
            setChannelData("Kanavaa ei löytynyt");
        }
        isLoading(false);
    }

    useEffect(() => {
        getChannelData();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <Page className="ChannelsPage">
            {loading ? <LoadingIndicator /> : null}
            {channelData !== undefined && typeof (channelData) !== 'string' ?
                <Channel data={channelData} /> : typeof (channelData) === 'string' ?
                    <h1>{channelData}</h1> : null}
        </Page>
    );
}

export default ChannelsPage;
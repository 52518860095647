import Alert from 'components/Alert';
import Footer from 'components/Footer';
import Header from 'components/Header';
import { scrollToTop } from 'constants/functions';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import './Page.scss';

type PageProps = {
    className: string;
}

const Page: React.FC<PageProps> = (props) => {
    const history = useHistory();
    useEffect(() => {
        scrollToTop();
    }, [history.length]);


    return (
        <>
            <Alert />
            <Header />
            <main className={`Page ${props.className}`}>
                {props.children}
            </main>
            <Footer />
        </>
    );
}

export default Page;
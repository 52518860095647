import { ApiCalls } from "constants/classes/ApiCalls";
import axios from "axios";

export class LiveEvents extends ApiCalls {
    public async getLiveEvents(): Promise<{ [key: string]: any }>{
        let response: {[key: string]: any } | Error;
        try {
            response = await axios.get(`${this.url}${this.route}`).catch(err => {
                return {
                    status: err.response.status,
                    message: err.response.message
                };
            });
        } catch (error) {
            response = {
                status: 510,
                message: "Unknown error occurred"
            };
        }
        return response;
    }

    public async getNextLiveEvent(){
        let response: {[key: string]: any } | Error;        
        try {
            response = await axios.get(`${this.url}${this.route}`).catch(err => {                
                return {
                    status: err.response.status,
                    message: err.response.message
                };
            });
        } catch (error) {
            response = {
                status: 510,
                message: "Unknown error occurred"
            };
        }
        return response;
    }

    public async getLiveEvent(liveId: string): Promise<{ [key: string]: any }>{
        let response: {[key: string]: any } | Error;
        try {
            response = await axios.get(`${this.url}${this.route}/${liveId}`).catch(err => {
                return {
                    status: err.response.status,
                    message: err.response.message
                };
            });
        } catch (error) {
            response = {
                status: 510,
                message: "Unknown error occurred"
            };
        }
        return response;
    }

}
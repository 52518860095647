import { io, Socket } from "socket.io-client";
import axios from "axios";
import { apiURL, apiRoutes } from "constants/constants";

export class Access {
    public socketUrl = process.env?.NODE_ENV === "development" ? `http://localhost:3001` : `https://www.videokanava.fi`;
    public socket: Socket<any, any>;

    constructor(code: string, type: 'videos' | 'live', videoId: number, callback: (status: number, data?: {embed_code: string, chat?: 0 | 1}, message?: string) => void) {
        this.socket = io(this.socketUrl, {
            transports: ["websocket", "polling"],
            path: process.env?.NODE_ENV === "development" ? "/clients" : "/api/clients",
            query: {
                code,
                type,
                videoId: videoId.toString(),
            }
        });
        this.socket.on("connect_error", () => {
            this.socket.io.opts.transports = ["polling", "websocket"];
        });

        this.socket.on("connect", () => {
        });

        this.socket.on("response", async (response: { code: string, clientId: string, type: 'videos' | 'live' }) => {
            let body = JSON.stringify({
                code: response.code,
                clientId: response.clientId,
                type: response.type,
                videoId
            });
            let result = await axios.post(`${apiURL}${apiRoutes.access}`, body, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
            
            if (result && result.data.data) {
                callback(result.status, result.data.data);
            }
        });

        this.socket.on("disconnect", () => {
        })

        this.socket.on("forbidden", (response: { status: number, message: string }) => {
            callback(response.status, undefined, response.message);
        });

    }

    public disconnect() {
        this.socket.disconnect();
    }

}
import CartContainer from 'components/CartContainer';
import Page from 'components/Page';
import React from 'react';
import './CartPage.scss';

const CartPage: React.FC = () => {
    return (
        <Page className="CartPage">
            <CartContainer />
        </Page>
    );
}
export default CartPage;
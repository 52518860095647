import { sleep } from 'constants/functions';
import React from 'react';
import './Button.scss';

type ButtonProps = {
    className?: string;
    type: "button-regular" | "button-no-padding" | "button-no-content" | "submit-regular" | "submit-no-padding"; // TODO add more button variants?
    color: "main-color" | "secondary-color" | "tertiary-color" | "button-transparent";
    onClick?: (e?: React.MouseEvent<HTMLButtonElement>) => void;
    onFocus?: (e?: React.FocusEvent<HTMLButtonElement>) => void;
    tabIndex?: number;
    ariaLabel?: string;
    isTitle?: string;
}
const Button: React.FC<ButtonProps> = (props) => {
    // to avoid onfocus firing first
    const onFocus = async (e: any) => {
        if (props.onFocus) {
            await sleep(100);
            props.onFocus(e);
        }
    }
    return (
        <button 
        aria-label={props.ariaLabel} 
        tabIndex={props.tabIndex} 
        onClick={props.onClick} 
        onFocus={onFocus} 
        type={props.type.includes("submit") ? "submit" : "button"} 
        className={`Button${props.className ? " " + props.className + " " : " "}${props.type} ${props.color}`}>
            {props.color !== "button-transparent" ? <div className={'button-background'}></div> : null}
            <span className={props.isTitle}>{props.children}</span>
        </button>
    );
}

export default Button;
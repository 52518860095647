import React from 'react';
import './FrontPage.scss';

import Page from 'components/Page';
import MOTDS from './MessagesOfTheDay';
import PageDescription from 'components/PageDescription';


const FrontPage: React.FC = () => {
    return (
        <Page className="FrontPage">
            <MOTDS />
            <PageDescription title="VIDEOKANAVA">
                <p>Tältä sivustolta voit ostaa katseluoikeuksia Kulttuuriosuuskunta TERÄn tarjoamiin livelähetyksiin ja videomateriaaleihin.</p>
            </PageDescription>
            <div className="content">
            </div>
        </Page>
    );
}

export default FrontPage;
import React, { useEffect, useState, useRef, useContext } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import Link from 'components/Link';
import './Header.scss';
import { apiRoutes, routes } from 'constants/constants';
import { clearFocus, filterArrayDuplicates, sleep, matchStringToArrValues } from 'constants/functions';
import SearchIcon from 'assets/images/svg/SearchIcon';
import Button from 'components/Button';
import CartIcon from 'assets/images/svg/CartIcon';
import MenuIcon from 'assets/images/svg/MenuIcon';
import { VideosContext } from 'contexts/VideosContext/reducer';
import { SearchContext } from 'contexts/SearchContext/reducer';
import { ChannelsContext } from 'contexts/ChannelsContext/reducer';
import { Channels } from 'constants/classes/Channels';
import { channelsActions } from 'contexts/ChannelsContext/actions';
import { videosActions } from 'contexts/VideosContext/actions';
import { searchActions } from 'contexts/SearchContext/actions';
import Logo from 'assets/images/svg/Logo';
import { isSafari } from 'react-device-detect';
// TODO might break
const SafariHeaderStyles = isSafari ? require('./SafariHeaderStyles') : null;

type SearchBarProps = {
    headerRef: React.MutableRefObject<HTMLElement | null>;
}

const SearchBar: React.FC<SearchBarProps> = (props) => {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const formRef = useRef<HTMLFormElement | null>(null);
    const suggestionsRef = useRef<HTMLDivElement | null>(null);
    const [searchInput, setSearchInput] = useState("");
    const [suggestions, setSuggestions] = useState<any[]>([]);
    const [currentSuggestion, setCurrentSuggestion] = useState<number | null>(null);
    const videos = useContext(VideosContext);
    const channels = useContext(ChannelsContext);
    const { state, dispatch } = useContext(SearchContext);
    const history = useHistory();

    const getInputValue = (): string => {
        if (inputRef.current) {
            return inputRef.current.value;
        }
        return "";
    }

    const findSuggested = (e: React.MouseEvent<HTMLButtonElement>, suggestion: string) => {
        e.preventDefault();        
        if (inputRef.current && formRef.current) {
            inputRef.current.value = suggestion;
            if (typeof formRef.current.requestSubmit === 'function') {
                formRef.current.requestSubmit();                
            } else {
                history.push('/search/' + suggestion);
            }
        }
    }

    const onChange = () => {
        let inputValue = getInputValue();
        let searchSuggestions: string[] = [];
        setSearchInput(inputValue);
        if (!currentSuggestion) {
            if (state.searchSuggestions.length > 0 && inputValue.length > 0) {
                for (let suggestion of state.searchSuggestions) {
                    if (suggestion.toLowerCase().includes(inputValue.toLowerCase())) {
                        searchSuggestions.push(suggestion);
                    }
                }

                setSuggestions(
                    matchStringToArrValues(state.searchSuggestions, inputValue).slice(
                        0,
                        state.searchSuggestions.length > 6 ? 6 : state.searchSuggestions.length
                    )
                );
                if (suggestionsRef.current) {
                    suggestionsRef.current.className = "search-suggestions active";
                }
            } else if (suggestions.length > 0) {
                setSuggestions([]);
                if (suggestionsRef.current) {
                    suggestionsRef.current.className = "search-suggestions";
                }
            }
        }
    }

    const getChannelContent = (ch: Channels, id: number) => {
        ch.getLiveEventsFromChannel(id).then(response => {
            videos.dispatch(
                {
                    type: videosActions.SET_ALL_LIVE_EVENTS,
                    value: {
                        channelId: id,
                        liveEvents: response.data
                    }
                }
            );
        });
        ch.getVideosFromChannel(id).then(response => {
            videos.dispatch(
                {
                    type: videosActions.SET_ALL_VIDEOS,
                    value: {
                        channelId: id,
                        videos: response.data
                    }
                }
            );
        });

    }

    const getAllContent = (): void => {
        const ch = new Channels(apiRoutes.channels);
        ch.getChannels().then(async (response) => {
            if (!response.data) return;
            await channels.dispatch({ type: channelsActions.SET_ALL_CHANNELS, value: response.data });
            for (let channel of response.data) {
                getChannelContent(ch, channel.id);
            }
        });
    }

    const setSearchSuggestions = () => {
        let suggestions: string[] = [];
        for (let key in videos.state) {
            for (let video of videos.state[key].videos!) {
                suggestions = [
                    ...suggestions,
                    ...[video.title, video.channel_title],
                    ...video.tags];
            }
            for (let live of videos.state[key].liveEvents!) {
                suggestions = [
                    ...suggestions,
                    ...[live.title, live.channel_title],
                    ...live.tags];
            }
        }
        dispatch({ type: searchActions.SET_SEARCH_SUGGESTIONS, value: filterArrayDuplicates(suggestions) });
    }

    const focusSearch = () => {
        props.headerRef.current?.classList.add('search-active');
        // set cursor to end, abit buggy if jumping between pages
        if (inputRef.current) {
            const inputValue = inputRef.current.value;
            inputRef.current.value = "";
            inputRef.current.value = inputValue;
        }
        if (!channels.state.searchedAllChannels) {
            getAllContent();
        }
    }

    const blurSearch = async (e: any) => {
        setSearchInput(getInputValue());
        let waitTime = isSafari ? 500 : 100;
        await sleep(waitTime);
        props.headerRef.current?.classList.remove('search-active');
        setSuggestions([]);
        if (suggestionsRef.current) {
            suggestionsRef.current.className = "search-suggestions";
        }
    }

    const scrollThroughSuggestions = (e: KeyboardEvent) => {
        if (suggestions.length > 0 && inputRef.current) {
            if (e.key === 'ArrowDown' || e.code === 'ArrowDown') {
                if (currentSuggestion === null) {
                    setCurrentSuggestion(0);
                    inputRef.current.value = suggestions[0];
                } else if (currentSuggestion + 1 < suggestions.length) {
                    inputRef.current.value = suggestions[currentSuggestion + 1];
                    setCurrentSuggestion(currentSuggestion + 1);
                }
            } else if (e.key === 'ArrowUp' || e.code === 'ArrowUp') {
                if (currentSuggestion && currentSuggestion > 0) {
                    inputRef.current.value = suggestions[currentSuggestion - 1];
                    setCurrentSuggestion(currentSuggestion - 1);
                }
            }
        } else if (currentSuggestion || (e.key !== 'ArrowDown' && e.code !== 'ArrowDown' && e.key !== 'ArrowUp' && e.code !== 'ArrowUp')) {
            setCurrentSuggestion(null);
        }
    }

    const executeSearch = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        clearFocus(100);
        if (routes.searchPage.withParams) {
            history.push(`${routes.searchPage.withParams({ input: getInputValue() })}`);
        }
    }

    //TODO might cause performance issues
    useEffect(() => {
        setSearchSuggestions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [videos.state]);

    useEffect(() => {
        document.addEventListener('keydown', scrollThroughSuggestions);
        return (() => {
            document.removeEventListener('keydown', scrollThroughSuggestions);
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [suggestions, currentSuggestion])

    return (
        <div className="SearchBar" onBlur={blurSearch} onFocus={focusSearch}>
            <SearchIcon scale={1} hidden={searchInput !== ""} />
            <form ref={formRef} onSubmit={executeSearch} action={routes.searchPage.route} autoComplete="off">
                <div className="search-bar-input">
                    <input
                        ref={inputRef}
                        onChange={onChange}
                        required
                        aria-label="Haku"
                        type="text"
                        name="input"
                        className="search-bar-text-input" />
                    <div className="suggestion-slide-hide"></div>
                    <div ref={suggestionsRef} className="search-suggestions">
                        {suggestions.map((suggestion, index) =>
                            <button tabIndex={-1} type="button" onClick={(e) => findSuggested(e, suggestion)} className={'search-suggestion'} key={index}>{suggestion}</button>)}
                    </div>
                </div>
                <Button ariaLabel="Suorita haku" type="submit-regular" color="main-color">
                    Hae
                </Button>
            </form>
        </div>
    );
}

const Cart: React.FC = () => {
    const history = useHistory();
    const navigateToCart = () => {
        history.push(routes.cartPage.route);
    }
    return (
        <Button ariaLabel="Ostoskori" onClick={navigateToCart} className="Cart" type="button-no-padding" color="button-transparent">
            <CartIcon scale={1.2} />
        </Button>
    );
}

const Navigation: React.FC = () => {
    const location = useLocation();
    const [activePage, setActivePage] = useState(location.pathname);
    const navRef = useRef<HTMLElement | null>(null);

    const focusNavigation = () => {
        navRef.current?.focus();
    }

    const clearNavFocus = () => {
        setActivePage(location.pathname);
        clearFocus(100);
    }

    // TODO this might cause focus problems
    useEffect(() => {
        if (activePage !== location.pathname) {
            clearNavFocus();
        }
    });

    return (
        <>
            <Button className="mobile-menu-button" onFocus={focusNavigation} onClick={focusNavigation} type="button-no-padding" color="button-transparent">
                <MenuIcon scale={0.7} />
            </Button>
            <nav
                ref={navRef}
                className="Navigation"
                tabIndex={-1}>
                <ul>
                    {Object.keys(routes).map(route => {
                        if (!routes[route].menuLink) return null;
                        return (
                            <li key={route} className={location.pathname === routes[route].route ? "active-page" : undefined}>
                                <Link to={routes[route].route} color="link-light">
                                    {routes[route].name}
                                </Link>
                                <span className="link-underline"></span>
                            </li>);
                    }
                    )}
                </ul>
            </nav>
        </>
    );
}

const Header: React.FC = () => {
    const headerRef = useRef<HTMLElement | null>(null);
    const history = useHistory();
    return (
        <header ref={headerRef} className='Header'>
            {isSafari ? <SafariHeaderStyles /> : null}
            <Button 
                ariaLabel="Linkki etusivulle" 
                onClick={() => history.push(routes.frontPage.route)} 
                className='logo' 
                type="button-no-padding" 
                color="button-transparent">
                <Logo scale={1} color={"#FFF"} />
            </Button>
            <SearchBar headerRef={headerRef} />
            <Cart />
            <Navigation />
        </header>
    );
}

export default Header;


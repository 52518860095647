import Button from 'components/Button';
import Link from 'components/Link';
import Page from 'components/Page';
import { Prices } from 'constants/classes/Prices';
import { apiRoutes, routes } from 'constants/constants';
import { cartActions } from 'contexts/CartContext/actions';
import { CartContext, TProvider, TTickets, TTicketType } from 'contexts/CartContext/reducer';
import { storeActions } from 'contexts/StoreContext/actions';
import { StoreContext } from 'contexts/StoreContext/reducer';
import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import './PaymentsPage.scss';

const PaymentsPage: React.FC = () => {
    const { state, dispatch } = useContext(CartContext);
    const storeContext = useContext(StoreContext);
    const history = useHistory();

    const getTotalAmount = () => {
        const findPrice = (amount: number, pricingId: number) => {
            for (let prices in storeContext.state.prices) {
                for (let price of storeContext.state.prices[prices]) {
                    if (price.pricing_id === pricingId) {
                        return price.price * amount;
                    }
                }

            }
            return 0;
        }
        let totalAmount = 0;
        for (let type in state.tickets) {
            for (let id in state.tickets[type as TTicketType]) {
                for (let pricingId in state.tickets[type as TTicketType][id]) {
                    totalAmount += findPrice(state.tickets[type as TTicketType][id][pricingId], Number(pricingId));

                }
            }

        }
        return totalAmount.toFixed(2) + '€';
    }

    const parameterToInput = (param: { name: string, value: string }, index: number) => <input key={index} type='hidden' name={param.name} value={param.value} />;

    const renderPaymentMethod = (provider: TProvider) => <form key={provider.name} method='POST' action={provider.url}>
        {provider.parameters.map((params, index) => parameterToInput(params, index))}
        <Button
            type="submit-no-padding"
            color="tertiary-color"
            className="payment-provider">
            <img src={provider.svg} alt={provider.name} />
        </Button>
    </form>

    const cancelPayment = () => {
        history.push(routes.paymentsPage.route + "/cancel");
    }
    const backToCart = async () => {
        await dispatch({ type: cartActions.SET_PAYMENT_INFO, value: undefined });
        history.goBack();
    }

    const testDevPayment = () => {
        if (state.paymentInfo) {
            history.push(routes.paymentsPage.route + "/success?checkout-transaction-id=" + state.paymentInfo.transactionId);
        }
    }
    const getTicketAmount = (tickets: TTickets):number => {
        let amount = 0;
        for(let ticket in tickets) {
            for(let pricingId in tickets[ticket]){
                amount += tickets[ticket][pricingId];
            }
        }   
        return amount;
    }
    const getTotalTicketAmount = () => {
        return getTicketAmount(state.tickets.channels) +
        getTicketAmount(state.tickets.videos) +
        getTicketAmount(state.tickets.live);
    }
    const groupPaymentProviders = () => {
        if (state.paymentInfo) {
            const mobile: JSX.Element[] = [];
            const bank: JSX.Element[] = [];
            const creditCard: JSX.Element[] = [];
            const credit: JSX.Element[] = [];
            for (let provider of state.paymentInfo.providers) {
                if (provider.group === 'mobile') {
                    mobile.push(renderPaymentMethod(provider));
                } else if (provider.group === 'bank') {
                    bank.push(renderPaymentMethod(provider));
                } else if (provider.group === 'creditcard') {
                    creditCard.push(renderPaymentMethod(provider));
                } else if (provider.group === 'credit') {
                    credit.push(renderPaymentMethod(provider));
                }
            }
            return <>
                <div className="payment-information">
                    <h3>Maksun tiedot</h3>
                    <p>Asiakkaan sähköpostiosoite: <b>{state.customerEmail}</b></p>
                    <p>Lippujen lukumäärä: <b>{getTotalTicketAmount()}</b></p>
                    <h2>Yhteissumma (sis. ALV 24%): {getTotalAmount()}</h2>
                </div>
                <div className="payment-providers">
                    <h3>Valitse maksutapa:</h3>
                    <div className="bank">
                        <h4>Verkkopankki</h4>
                        {bank}
                    </div>
                    <div className="mobile">
                        <h4>Mobiili</h4>
                        {mobile}
                    </div>
                    <div className="creditcard">
                        <h4>Korttimaksu</h4>
                        {creditCard}
                    </div>
                    <div className="credit">
                        <h4>Lasku</h4>
                        {credit}
                    </div>
                    <p className="payment-terms">Valitsemalla maksutavan hyväksyt <Link color="link-dark" to="https://www.paytrail.com/kuluttaja/maksupalveluehdot" target="_blank">maksupalveluehdot</Link></p>
                    <div className="button-container">
                        {process.env?.NODE_ENV === "development" ? <Button onClick={testDevPayment} type="button-regular" color="main-color">Testimaksu</Button> : null}
                        <Button className="cancel" onClick={backToCart} type="button-regular" color="main-color">Takaisin ostoskoriin</Button>
                        <Button className="cancel" onClick={cancelPayment} type="button-regular" color="main-color">Peruuta</Button>
                    </div>
                </div>
            </>
        } else {
            return <h1 style={{ margin: 'auto' }}>Ei aktiivisia maksutapahtumia</h1>;
        }
    }
    useEffect(() => {
        if (!storeContext.state.searchedAllPrices) {
            new Prices(apiRoutes.prices).getAllPrices().then(prices => {
                storeContext.dispatch({ type: storeActions.SET_ALL_PRICES, value: prices.data });
            });
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <Page className="PaymentsPage">
            {groupPaymentProviders()}
        </Page>
    );
}

export default PaymentsPage;